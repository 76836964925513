var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar",attrs:{"role":"navigation","aria-label":"main navigation"}},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"navbar-brand"},[_c('router-link',{class:['navbar-item'],attrs:{"to":"/"}},[_c('img',{attrs:{"src":require("@/assets/images/nbf.png"),"width":"100","height":"100"}})]),_c('a',{staticClass:"navbar-burger burger",attrs:{"role":"button","aria-label":"menu","aria-expanded":"false"},on:{"click":_vm.navMenu}},[_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}}),_c('span',{attrs:{"aria-hidden":"true"}})])],1),_c('div',{ref:"navMenu",staticClass:"navbar-menu has-text-weight-medium"},[_c('div',{staticClass:"navbar-start"},[_c('router-link',{class:[
            'navbar-item',
            _vm.$route.path.includes('calculation') ? 'is-active' : '',
          ],attrs:{"to":"/"}},[_vm._v(" "+_vm._s(_vm.$t('menu.calculations'))+" ")]),(_vm.hasPermission('settings'))?_c('router-link',{class:[
            'navbar-item',
            _vm.$route.path.includes('settings') ? 'is-active' : '',
          ],attrs:{"to":"/settings/"}},[_vm._v(" "+_vm._s(_vm.$t('menu.settings'))+" ")]):_vm._e()],1),_c('div',{staticClass:"navbar-end"},[_c('div',{staticClass:"navbar-item"},[_c('span',{staticClass:"icon is-small has-margin-right-5"},[_c('font-awesome-icon',{attrs:{"icon":['fal', 'user-circle']}})],1),_c('span',[_vm._v(_vm._s(_vm.account.user.firstName))])]),_c('div',{staticClass:"navbar-item"},[_c('router-link',{attrs:{"to":"/logout"}},[_c('span',[_vm._v(_vm._s(_vm.$t('menu.logout')))])])],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }