<template>
  <div v-if="visible" class="notification has-text-black has-padding-70">
    <button class="delete" @click="hideNotification" />
    <span v-if="data.component && data.component.name === 'kalkyl-intro'">
      <h2 class="has-margin-bottom-10">
        {{
          $t('Hej {username}, kom igång med din första kalkyl', {
            username: account.user.firstName,
          })
        }}
      </h2>
      {{ $t('Det finns några viktiga saker att kontrollera när du gör din första kalkyl') }},<br />
      {{
        $t(
          'vi har samlat det tillsammans med tips i ett hjälpavsnitt som hjälper dig göra en korrekt och rättvisande kalkyl'
        )
      }}.
      <!-- <br/>
            <br/> -->
      <!-- <button class="button is-dark">{{ $t('Så gör du din första kalkyl') }}</button> -->
    </span>
    <template v-else>
      <span v-if="data.text">
        {{ $t(data.text) }}
      </span>
      {{ /* eslint-disable-next-line */ }}
            <span v-if="data.html" v-html="data.html" />
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
    visible() {
      return !this.account.introduction;
    },
  },
  methods: {
    hideNotification() {
      this.$store.dispatch('account/introduction', true);
    },
  },
};
</script>
<style lang="scss" scoped>
.notification {
  background: #98aeac;
}
</style>
