// let apiURL = 'http://localhost:4000';
import { authedFetch } from '../helpers';

const apiURL = process.env.VUE_APP_APIURL;

export const userService = {
  login,
  logout,
  register,
  resetPassword,
  changePassword,
  validateResetToken,
  getAll,
  getById,
  update,
  delete: _delete,
};

function login(email, password, remember) {
  const requestOptions = {
    method: 'POST',
    // headers: { 'Content-Type': 'application/json' },
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
    },
    // body: JSON.stringify({ username, password })
    body: `email=${encodeURI(email)}&password=${encodeURI(password)}&remember=${encodeURI(
      remember
    )}`,
  };

  return fetch(`${apiURL}/users/authenticate`, requestOptions)
    .then(handleResponse)
    .then((response) => {
      if (response.token) {
        localStorage.setItem('user', JSON.stringify(response));
      }
      return response;
    });
}

function logout() {
  localStorage.removeItem('user');
}

function register(user) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };
  return fetch(`${apiURL}/users/register`, requestOptions).then(handleResponse);
}

function resetPassword(email) {
  // Request as encoded form data
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' },
    body: `email=${encodeURI(email)}`,
  };
  return fetch(`${apiURL}/users/password/reset`, requestOptions).then(handleResponse);
}

function changePassword(token, password, confirmPassword) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' },
    body: `token=${encodeURI(token)}&password=${encodeURI(password)}&confirmPassword=${encodeURI(
      confirmPassword
    )}`,
  };
  return fetch(`${apiURL}/users/password/change`, requestOptions).then(handleResponse);
}

function validateResetToken(token) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8' },
    body: `token=${encodeURI(token)}`,
  };
  return fetch(`${apiURL}/users/password/reset/validate`, requestOptions).then(handleResponse);
}

function getAll() {
  const requestOptions = {
    method: 'GET',
  };
  return authedFetch(`${apiURL}/users`, requestOptions).then(handleResponse);
}

function getById(id) {
  const requestOptions = {
    method: 'GET',
  };
  return authedFetch(`${apiURL}/users/${id}`, requestOptions).then(handleResponse);
}

function update(user) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(user),
  };
  return authedFetch(`${apiURL}/users/${user.id}`, requestOptions).then(handleResponse);
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  const requestOptions = {
    method: 'DELETE',
  };
  return authedFetch(`${apiURL}/users/${id}`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        logout();
        location.reload();
      }
      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }
    return data;
  });
}
