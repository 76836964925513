import { userService } from '.';
import { store } from '../store';
import { authedFetch } from '../helpers';

const apiURL = process.env.VUE_APP_APIURL;

function addParams(url, params) {
  let queryExists = !!url.match(/\?/);
  for (const key in params) {
    const val = params[key];
    url += queryExists ? `&${key}=${val}` : `?${key}=${val}`;
    queryExists = true;
  }

  return url;
}

const getData = (route) => {
  const requestOptions = {
    method: 'GET',
    // mode: 'cors',
  };

  return authedFetch(
    addParams(`${apiURL}${route}`, {
      region: store.getters['account/region'],
    }),
    requestOptions
  ).then(handleResponse);
};

const postData = (route, data, headers = null) => {
  let fetchHeaders = {};
  // headers['Content-Type'] = 'application/json; charset=utf-8';
  fetchHeaders['Content-Type'] = 'application/x-www-form-urlencoded; charset=utf-8';
  fetchHeaders = { ...fetchHeaders, ...headers };
  if (fetchHeaders['Content-Type'] == 'multipart/form-data') delete fetchHeaders['Content-Type']; // multipart/form-data fix
  const requestOptions = {
    method: 'POST',
    // mode: 'cors',
    headers: fetchHeaders,
    // body: JSON.stringify(data)
    body: data,
  };
  return authedFetch(
    addParams(`${apiURL}${route}`, {
      region: store.getters['account/region'],
    }),
    requestOptions
  ).then(handleResponse);
};

function importUsersAndCompanies(file, sendEmail) {
  const data = new FormData();
  data.append('file', file);
  data.append('sendEmail', sendEmail);
  const requestOptions = {
    method: 'POST',
    body: data,
  };
  return authedFetch(
    addParams(`${apiURL}/import`, {
      region: store.getters['account/region'],
    }),
    requestOptions
  ).then(handleResponse);
}

export { postData, getData, importUsersAndCompanies };

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      const error = (data && data.message) || response.statusText;
      if (error == 'Unauthorized') {
        userService.logout();
        window.location = '/login';
      }
      return Promise.reject(error);
    }
    return data;
  });
}
