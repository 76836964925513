<template>
  <a :class="data.className" :href="data.link">
    {{ $t(data.text) }}
  </a>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="scss" scope></style>
