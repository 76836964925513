import VueCookies from 'vue-cookies';
import { userService } from '../services';
import { router } from '../helpers';

const region = VueCookies.get('kalkyl_region') || 'NO';
const introduction = VueCookies.get('kalkyl_introduction') == 'y';
const user = JSON.parse(localStorage.getItem('user'));
const state = user
  ? {
    status: { loggedIn: true },
    user,
    region,
    introduction,
  }
  : {
    status: {},
    user: null,
    region,
    introduction,
  };

const actions = {
  login({ dispatch, commit }, { username, password, remember }) {
    commit('loginRequest', { username });
    userService.login(username, password, remember).then((response) => {
      if (response.token) {
        commit('loginSuccess', response);
        router.push('/');
      } else {
        commit('loginFailure', response.message);
        dispatch('alert/error', response.message, { root: true });
      }
    });
  },
  logout({ commit }) {
    userService.logout();
    commit('logout');
  },
  resetPassword({ dispatch }, { email }) {
    userService.resetPassword(email).then(() => {
      router.push('/login');
      dispatch('alert/success', 'Ett e-postmeddelande har skickats till din e-postadress.', { root: true });
    }).catch((error) => {
      dispatch('alert/error', error, { root: true });
    })
  },
  changePassword({ dispatch }, { token, password, confirmPassword }) {
    userService.changePassword(token, password, confirmPassword).then(() => {
      router.push('/login');
      dispatch('alert/success', 'Ditt lösenord har ändrats.', { root: true });
    }).catch((error) => {
      dispatch('alert/error', error, { root: true });
    })
  },
  register({ dispatch, commit }, user) {
    commit('registerRequest', user);
    userService.register(user).then(
      (user) => {
        commit('registerSuccess', user);
        router.push('/login');
        setTimeout(() => {
          dispatch('alert/success', 'Registration successful', {
            root: true,
          });
        });
      },
      (error) => {
        commit('registerFailure', error);
        dispatch('alert/error', error, { root: true });
      }
    );
  },
  region({ commit }, region) {
    commit('region', region);
  },
  introduction({ commit }, introduction) {
    commit('introduction', introduction);
  },
};

const mutations = {
  loginRequest(state, user) {
    state.status = { loggingIn: true };
    state.user = user;
  },
  loginSuccess(state, user) {
    state.status = { loggedIn: true };
    state.user = user;
  },
  loginFailure(state) {
    state.status = {};
    state.user = null;
  },
  logout(state) {
    state.status = {};
    state.user = null;
  },
  registerRequest(state) {
    state.status = { registering: true };
  },
  registerSuccess(state) {
    state.status = {};
  },
  registerFailure(state) {
    state.status = {};
  },
  region(state, region) {
    state.region = region;
    VueCookies.set('kalkyl_region', region);
  },
  introduction(state, introduction) {
    state.introduction = introduction;
    VueCookies.set('kalkyl_introduction', introduction ? 'y' : 'n');
  },
};

const getters = {
  region(state) {
    return state.region;
  },
};

export const account = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
