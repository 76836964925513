<template>
  <div class="link-container">
    <router-link :class="data.className" :to="data.link">
      {{ data.translate ? $t(data.text) : data.text }}
    </router-link>
    <span v-if="data.subtitle" class="calc-subtitle secondary-color">{{
      data.translate ? $t(data.subtitle) : data.subtitle
    }}</span>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="scss" scope>
.link-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.calc-subtitle {
  font-size: 0.75rem;
}
</style>
