<template>
  <fragment>
    <header v-if="account.user && parent">
      <Menu />
    </header>
    <main v-if="parent">
      <section class="section">
        <div class="container">
          <Alert />
          <slot />
        </div>
      </section>
    </main>
    <slot v-if="!parent" />
  </fragment>
</template>

<script>
import { mapState } from 'vuex';
import Menu from './Menu.vue';
import Alert from '../components/Alert.vue';

export default {
  components: {
    Menu,
    Alert,
  },
  props: {
    parent: {
      type: Boolean,
      default: null,
    },
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
};
</script>

<style lang="scss" scope></style>
