<template>
  <div class="level">
    <div class="level-left">
      <div v-for="(item, index) in data.left" :key="item.type + index" class="level-item">
        <LevelItem :data="item" />
      </div>
    </div>
    <div v-if="data.right" class="level-right">
      <div v-for="(item, index) in data.right" :key="item.type + index" class="level-item">
        <LevelItem :data="item" />
      </div>
    </div>
  </div>
</template>

<script>
import LevelItem from './LevelItem.vue';

export default {
  components: {
    LevelItem,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="scss" scope></style>
