<template>
  <main-layout :parent="parent">
    <Notification
      v-if="
        data &&
        data.notification &&
        (data.notification.text || data.notification.html || data.notification.component.name)
      "
      :data="data.notification"
    />
    <form
      v-if="data && data.form && data.form.action"
      id="mainform"
      ref="mainform"
      :method="data.form.method"
      :enctype="data.form.enctype"
      :action="data.form.action"
      :class="data.form.className"
    >
      <fragment v-if="data.form.fields">
        <xfragment v-for="(item, index) in data.form.fields" :key="item.type + index">
          <TypeLoader :data="item" />
        </xfragment>
      </fragment>
      <xfragment v-for="(item, index) in sections" :key="item.type + index">
        <TypeLoader :data="item" />
      </xfragment>
      <div v-if="data.form.button" class="field has-text-centered">
        <button type="button" class="button is-primary" @click="send">
          {{ $t(data.form.button) }}
        </button>
      </div>
    </form>
    <fragment v-else-if="data && (!data.form || !data.form.action)">
      <xfragment v-for="(item, index) in data.sections" :key="item.type + index">
        <TypeLoader :data="item" />
      </xfragment>
    </fragment>
    <div v-else class="has-text-centered">
      <span class="icon is-large">
        <font-awesome-icon icon="spinner" class="fa-5x" spin />
      </span>
    </div>
    <div v-show="loading !== false" class="loading-spinner">
      <span class="icon is-large" :title="loading !== true ? loading : ''">
        <font-awesome-icon v-if="loading === true" icon="spinner" class="fa-5x" spin />
        <font-awesome-icon v-else icon="exclamation-circle" />
      </span>
    </div>
  </main-layout>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import MainLayout from '../layout/Main.vue';
// import Input from '../components/Input.vue';
import Notification from '../components/Notification.vue';

export default {
  components: {
    MainLayout,
    Notification,
    // Input,
  },
  beforeRouteLeave(to, from, next) {
    const goNext = true;
    // let meta = this.$route.meta
    // if(meta.promptUnsavedChanges && !meta.isSaving) {
    //     goNext = confirm("You have unsaved changes.\nAre you sure you want to leave the page?")
    // }
    // meta.isSaving = false
    next(goNext);
  },
  props: {
    api: {
      type: String,
      default: null,
    },
    parent: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      form: {},
    };
  },
  computed: {
    ...mapState({
      data: (state) => state.api.data,
      // partialData: state => state.api.partialData,
      path: (state) => state.api.path,
      apiForm: (state) => state.api.form,
      loading: (state) => state.api.loading,
    }),
    sections() {
      if (!this.data || !this.data.sections) return null;
      const { tab } = this.$route.meta;
      // console.log("Tab:", tab)
      // console.timeEnd("auto_refresh")
      if (tab || tab === '') {
        return this.data.sections.filter((section) => (section.tab || '') === tab);
      }
      return this.data.sections;
    },
    url() {
      // let url = '/' + this.$i18n.locale;
      // let url = '/sv-SE';
      // url += this.api;
      let url = this.api;
      if (!this.$route.params) return url;
      for (const p in this.$route.params) {
        url = url.replace(`%${p}`, this.$route.params[p]);
      }
      return url;
    },
  },
  watch: {
    data(data) {
      // console.log(data)
      if (data && data.reload) location.reload();
      if (data && data.redirect && this.url != data.redirect) this.$router.push(data.redirect);
      if (data && data.form) this.setFormPath(data.form.action);
      this.newForm(data);
    },
    // partialData(data) {
    //     this.newForm(data)
    // },
    $route() {
      this.load(this.url);
    },
    $i18n() {
      this.load(this.url);
    },
  },
  created() {
    this.load(this.url);
    /* setTimeout(() => {
            this.postForm('/test', {});
        }, 3000); */
    if (this.$route.params.refresh) {
      this.$router.go({ name: 'logotypes' });
    }
  },
  methods: {
    ...mapActions('api', {
      load: 'get',
      postForm: 'post',
      newForm: 'newForm',
      setFormPath: 'formPath',
    }),
    send() {
      // var formData = new FormData(document.getElementById('mainform'));
      // var data = {};
      // formData.forEach((value, key) => {
      //     data[key] = value;
      // });
      let data = this.apiForm;
      data = Object.keys(data).map((key) => `${key}=${data[key]}`);
      data = data.join('&');
      this.postForm({ url: this.data.form.action, data });
    },
    // storeFormData() {
    //     const recursive = (data) => {
    //         for(let key in data) {
    //             if(!data.hasOwnProperty(key)) continue;
    //             let field = data[key]
    //             if(typeof field === 'object' && field !== null) {
    //                 if(field.key && field.value) {
    //                     this.setFormField({key: field.key, val: field.value})
    //                 } else {
    //                     recursive(field)
    //                 }
    //             }
    //         }
    //     }
    //     if(this.data) {
    //         // Add hidden fields
    //         if(this.data.form && this.data.form.fields) {
    //             for(let i=0; i < this.data.form.fields.length; i++) this.setFormField({key: this.data.form.fields[i].key, val: this.data.form.fields[i].value})
    //         }
    //         if(this.data.sections) recursive(this.data.sections)
    //     }
    // }
  },
};
</script>

<style lang="scss" scope>
.full-form {
  .label {
    margin-top: 10px;
  }
  .input,
  .select,
  .permissions {
    width: 100% !important;
    max-width: 500px;
  }
  .field .button {
    margin-top: 20px;
  }
}
span[data-tooltip],
th span.icon,
td span.icon {
  vertical-align: top;
  border-bottom: none;
}

.field.has-text-centered button {
  padding: 1.8em 5em;
  border: none;
}

.loading-spinner {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  background: white;
  border: 2px solid #4e5863;
  border-radius: 0.25rem;
  z-index: 10;
}

.spinner-box {
  overflow: hidden;
  transform: translateX(25px);
  opacity: 0;
  transition: opacity 0.5s, transform 0.5s;
}
.spinner-showing {
  opacity: 1;
  transform: translateX(0px);
}
</style>
