<template>
  <div :class="['section', data.className]">
    <h2 v-if="data.title" class="title is-size-3">
      {{ $t(data.title) }}
    </h2>
    <h2 v-if="data.subtitle" class="calc-subtitle">
      {{ $t(data.subtitle) }}
    </h2>
    <Level v-if="data.level" :data="data.level" />
    <xfragment v-for="(item, index) in data.views" :key="item.id + index">
      <TypeLoader :data="item" />
    </xfragment>
  </div>
</template>

<script>
import Level from './Level.vue';

export default {
  components: {
    Level,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="scss" scope>
.section {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
