export function authHeader() {
  const user = JSON.parse(localStorage.getItem('user'));
  if (user && user.token) {
    return { Authorization: `Bearer ${user.token}` };
  }
  return {};
}

export async function authedFetch(url, options = {}) {
  // Check if 'Authorization' header is set
  const auth = authHeader();
  if (!options.headers) {
    options.headers = {};
  }
  if (!options.headers.Authorization) {
    options.headers.Authorization = auth.Authorization;
  }

  const response = await fetch(url, options);

  if (response.status >= 400) {
    // log out if 401 response returned from api
    if (response.status === 401) {
      // auto logout if 401 response returned from api
      localStorage.removeItem('user');
      location.reload(true);
    }
    throw new Error('Bad response from server');
  }

  return response;
}
