<script>
// import _ from 'lodash'
import Chart from 'chart.js';
import { Line, mixins } from 'vue-chartjs';

export default {
  extends: Line,
  mixins: [mixins.reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: Object,
    },
    options: {
      type: Object,
      default: Object,
    },
  },
  data() {
    return {
      dataLength: 0,
      datasetLength: 0,
    };
  },
  watch: {
    // chartData: {
    //     immediate: true,
    //     handler () {
    //         const data = this.chartData.datasets.reduce((arr, dataset) => {
    //             dataset.data.reduce((arr2, data) => {
    //                 arr.push(data.y)
    //             }, [])
    //             return arr
    //         }, [])
    //         const max = Math.max(...data)
    //         const scaleBuffer = max + (max * 0.25)
    //         Chart.scaleService.updateScaleDefaults('linear', {
    //             ticks: {
    //                 max: scaleBuffer
    //             }
    //         })
    //         console.log(this.$data._chart)
    //     }
    // }
  },
  mounted() {
    // const vue = this
    // Extract data values & add buffer of 25%
    this.datasetLength = this.chartData.datasets.length;
    this.dataLength = this.chartData.labels.length;

    Chart.Tooltip.positioners.topleft = function () {
      return {
        x: 0,
        y: 0,
      };
      // if (!elements.length) {
      //     return false
      // }

      // let i, len
      // let x = 0
      // let y = 0

      // for (i = 0, len = elements.length; i < len; ++i) {
      //     const el = elements[i]
      //     if (el && el.hasValue()) {
      //         const pos = el.tooltipPosition()
      //         x += pos.x
      //         y += pos.y
      //     }
      // }

      // const width = vue.$el.clientWidth / 2 - (vue.dataLength * 4)
      // return {
      //     x: x - (width / vue.datasetLength / vue.dataLength),
      //     y: y - 5
      // }
    };

    // this.addPlugin({
    //     id: 'alwaysShowTooltips',
    //     beforeRender: function (chart) {
    //         if (chart.config.options.showAllTooltips) {
    //             // create an array of tooltips
    //             // we can't use the chart tooltip because there is only one tooltip per chart
    //             chart.pluginTooltips = []
    //             chart.config.data.datasets.forEach(function (dataset, i) {
    //                 chart.getDatasetMeta(i).data.forEach(function (sector, j) {
    //                     chart.options.greenTooltips = chart.options.greenTooltips || _.cloneDeep(chart.options.tooltips)
    //                     chart.options.redTooltips = chart.options.redTooltips || _.cloneDeep(chart.options.tooltips)
    //                     chart.options.grayTooltips = chart.options.grayTooltips || _.cloneDeep(chart.options.tooltips)

    //                     const hasLabel = !!chart.config.data.labels[j]
    //                     let color = null
    //                     if (hasLabel) {
    //                         color = 'red'
    //                         const current = dataset.data[j].y
    //                         const previous = j > 0 ? dataset.data[j - 1].y : dataset.firstData
    //                         if (j === 0) {
    //                             color = 'gray'
    //                         } else if (!previous || current > previous) {
    //                             color = 'green'
    //                         }
    //                     }

    //                     let options = chart.options.tooltips
    //                     switch (color) {
    //                     case 'green':
    //                         chart.options.greenTooltips.displayColors = false
    //                         chart.options.greenTooltips.backgroundColor = '#E9FAE5'
    //                         options = chart.options.greenTooltips
    //                         break
    //                     case 'red':
    //                         chart.options.redTooltips.displayColors = false
    //                         chart.options.redTooltips.backgroundColor = '#FAE5E5'
    //                         options = chart.options.redTooltips
    //                         break
    //                     case 'gray':
    //                         chart.options.grayTooltips.displayColors = false
    //                         chart.options.grayTooltips.backgroundColor = '#EEE'
    //                         options = chart.options.grayTooltips
    //                         break
    //                     }
    //                     chart.pluginTooltips.push(new Chart.Tooltip({
    //                         _chart: chart.chart,
    //                         _chartInstance: chart,
    //                         _data: chart.data,
    //                         _options: options,
    //                         _active: [sector]
    //                     }, chart))
    //                 })
    //             })

    //             // turn off normal tooltips
    //             chart.options.tooltips.enabled = false
    //         }
    //     },
    //     afterDraw: function (chart, easing) {
    //         if (chart.config.options.showAllTooltips) {
    //             // we don't want the permanent tooltips to animate, so don't do anything till the animation runs atleast once
    //             if (!chart.allTooltipsOnce) {
    //                 if (easing !== 1) return
    //                 chart.allTooltipsOnce = true
    //             }

    //             // turn on tooltips
    //             // chart.options.tooltips.enabled = false
    //             chart.options.greenTooltips.enabled = true
    //             chart.options.redTooltips.enabled = true
    //             Chart.helpers.each(chart.pluginTooltips, function (tooltip) {
    //                 tooltip.initialize()
    //                 tooltip.update()
    //                 // we don't actually need this since we are not animating tooltips
    //                 tooltip.pivot()
    //                 tooltip.transition(easing).draw()
    //             })
    //             // chart.options.tooltips.enabled = false
    //             chart.options.redTooltips.enabled = false
    //             chart.options.greenTooltips.enabled = false
    //         }
    //     }
    // })
    this.renderChart(this.chartData, this.options);
  },
};
</script>
