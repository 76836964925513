<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <div class="container">
      <div class="navbar-brand">
        <router-link to="/" :class="['navbar-item']">
          <img src="@/assets/images/nbf.png" width="100" height="100" />
        </router-link>
        <a
          role="button"
          class="navbar-burger burger"
          aria-label="menu"
          aria-expanded="false"
          @click="navMenu"
        >
          <span aria-hidden="true" />
          <span aria-hidden="true" />
          <span aria-hidden="true" />
        </a>
      </div>
      <div ref="navMenu" class="navbar-menu has-text-weight-medium">
        <div class="navbar-start">
          <router-link
            to="/"
            :class="[
              'navbar-item',
              $route.path.includes('calculation') ? 'is-active' : '',
            ]"
          >
            {{ $t('menu.calculations') }}
          </router-link>
          <router-link
            v-if="hasPermission('settings')"
            to="/settings/"
            :class="[
              'navbar-item',
              $route.path.includes('settings') ? 'is-active' : '',
            ]"
          >
            {{ $t('menu.settings') }}
          </router-link>
        </div>
        <div class="navbar-end">
          <!-- <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link">{{ $t('menu.language') }}</a>
            <div class="navbar-dropdown">
              <a
                v-for="item in localeList"
                :key="item.locale"
                :class="[
                  'navbar-item',
                  +(locale == item.locale ? 'is-active' : ''),
                ]"
                @click="setLocale(item.locale)"
              >
                <span class="icon has-margin-right-10 is-size-5">
                  <flag :iso="item.flag" />
                </span>
                <span>{{ $t(item.title) }}</span>
              </a>
            </div>
          </div> -->
          <div class="navbar-item">
            <span class="icon is-small has-margin-right-5">
              <font-awesome-icon :icon="['fal', 'user-circle']" />
            </span>
            <span>{{ account.user.firstName }}</span>
          </div>
          <div class="navbar-item">
            <router-link to="/logout">
              <span>{{ $t('menu.logout') }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      locale: 'nb-NO',
      localeList: [
        { title: 'Engelska', locale: 'en-US', flag: 'gb' },
        { title: 'Svenska', locale: 'sv-SE', flag: 'se' },
        { title: 'Norska', locale: 'nb-NO', flag: 'no' },
      ],
    };
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
    }),
  },
  methods: {
    ...mapActions('account', ['region']),
    setLocale(code) {
      this.locale = code;
      this.$i18n.locale = this.locale;
      this.region(this.locale.slice(-2));
      this.$router.go(0);
    },
    navMenu(event) {
      event.target.classList.toggle('is-active');
      this.$refs.navMenu.classList.toggle('is-active');
    },
    hasPermission(p = '') {
      return this.account?.user?.permissions?.includes(p);
    },
  },
};
</script>

<style lang="scss" scope>
.navbar {
  margin-top: 1em;
  @media (min-width: 1024px) {
    margin-top: 3em;
  }
  .navbar-item {
    img {
      max-height: none;
    }
  }
}
</style>
