<template>
  <div :class="['fragment', data.className]">
    <div class="title is-flex">
      <a v-if="type === 'expandable'" @click="isExpanded = !isExpanded">
        <h3 v-if="data.title" class="is-size-4 is-inline-block">
          {{ data.title.translate ? $t(data.title.text) : data.title.text }}
        </h3>
        <font-awesome-icon
          :icon="['fa', isExpanded ? 'angle-down' : 'angle-right']"
          class="expandable-caret"
        />
      </a>
      <h3 v-else-if="data.title" class="is-size-4">
        {{ data.title.translate ? $t(data.title.text) : data.title.text }}
      </h3>
      <div v-show="type === 'expandable' && !isExpanded">
        <TypeLoader v-for="(item, index) in data.minimized" :key="item.type + index" :data="item" />
      </div>
    </div>
    <!-- <div v-if="data.actions">
            <TableAction
                v-for="(item, index) in data.actions"
                :key="item.type + index"
                :data="item" />
        </div> -->
    <Level v-if="data.level" v-show="isExpanded" :data="data.level" />
    <TypeLoader v-for="(item, index) in data.prepend" :key="item.type + index" :data="item" />
    <Chart v-if="type == 'chart'" :data="data" />
    <table
      v-show="isExpanded"
      v-if="data.columns || data.rows"
      :key="data.key"
      :class="['table', 'is-hoverable', 'is-fullwidth', data.className]"
    >
      <thead v-if="data.columns">
        <tr>
          <th
            v-for="(th, thIndex) in data.columns"
            :key="'bodyth' + thIndex + thIndex"
            :class="th.className"
            :style="th.style"
          >
            <TypeLoader
              v-for="(item, index) in th.fields"
              :key="item.type + 'th' + thIndex + index"
              :data="item"
            />
          </th>
        </tr>
      </thead>
      <tbody v-if="data.rows">
        <tr v-for="(tr, trIndex) in data.actions" :key="'actiontr' + tr.key + trIndex">
          <td
            v-for="(td, tdIndex) in tr.columns"
            :key="'actiontd' + trIndex + tdIndex"
            :class="tr.className"
            :style="tr.style"
          >
            <TypeLoader
              v-for="(item, index) in td.fields"
              :key="'action' + td.type + trIndex + tdIndex + index"
              :data="{ ...item, ...{ ignored: true } }"
              @blur="tableAction(tr.type, $event)"
            />
          </td>
        </tr>
        <tr v-for="(tr, trIndex) in data.rows" :key="'bodytr' + tr.key + trIndex">
          <span v-if="tr.title" class="is-size-6">
            {{ $t(tr.title) }}
          </span>
          <td
            v-for="(td, tdIndex) in tr.columns"
            :key="'bodytd' + trIndex + tdIndex"
            :class="td.className"
            :style="td.style"
          >
            <TypeLoader
              v-for="(item, index) in td.fields"
              :key="item.type + trIndex + tdIndex + index"
              :data="item"
            />
          </td>
        </tr>
      </tbody>
    </table>
    <TypeLoader v-for="(item, index) in data.append" :key="item.type + index" :data="item" />
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapActions } from 'vuex';
import Chart from './Chart.vue';

import Level from './Level.vue';

export default {
  components: {
    Level,
    Chart,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      isExpanded: false,
      debounced: {},
    };
  },
  computed: {
    ...mapState({
      apiForm: (state) => state.api.form,
      formPath: (state) => state.api.formPath,
    }),
    type() {
      const split = this.data.type.split('-');
      return split.length > 1 ? split[1] : '';
    },
  },
  methods: {
    ...mapActions('api', {
      setFormField: 'form',
      postForm: 'post',
    }),
    tableAction(type, e) {
      const updateForm = () => {
        let data = this.apiForm;
        data = Object.keys(data).map((key) => `${key}=${data[key]}`);
        data = data.join('&');
        this.postForm({
          url: this.formPath,
          data,
          redirect: false,
          debounce: true,
        });
      };

      // debounce per key
      const key = this.data.key + e.key;
      if (!this.debounced[key]) {
        this.debounced[key] = _.debounce(
          (type, e) => {
            switch (type) {
              case 'split_costs':
                var dialogText = this.$t('split_costs_dialog_text', {
                  percent: e.value,
                  department: e.key?.replace('_', ' '),
                });
                this.$dialog
                  .confirm(dialogText, {
                    okText: this.$t('Fortsätt'),
                    cancelText: this.$t('Avbryt'),
                  })
                  .then(() => {
                    for (let rIndex = 0; rIndex < this.data.rows.length; rIndex++) {
                      const { columns } = this.data.rows[rIndex];
                      for (let cIndex = 0; cIndex < columns.length; cIndex++) {
                        const { fields } = columns[cIndex];
                        for (let i = 0; i < fields.length; i++) {
                          const field = fields[i];
                          if (field.key && field.key.endsWith(e.key)) {
                            this.setFormField({
                              key: field.key,
                              val: e.value,
                            });
                          }
                        }
                      }
                    }
                    e.callback(0); // Reset value
                    updateForm(); // Update form
                  })
                  .catch(() => {
                    e.callback(0); // Reset value
                  });
                break;
              default:
                updateForm(); // Update form
            }
          },
          e.debounce || e.debounce >= 0 ? e.debounce : 5000
        );
      }
      this.debounced[key](type, e);
    },
  },
  watch: {
    type: {
      immediate: true,
      handler(type) {
        if (type !== 'expandable') this.isExpanded = true;
      },
    },
  },
};
</script>

<style lang="scss" scope>
@import '~bulma/sass/utilities/_all';
$under: #bedad8;

div.section {
  border-bottom: 1px solid grey;
  margin-bottom: 2em;
  overflow-x: auto;
}
.table {
  margin-bottom: 3em !important;
  table-layout: fixed;

  th,
  td,
  span,
  input {
    vertical-align: middle !important;
  }

  th {
    border-bottom: none;
    width: 200px;
    &:first-of-type {
      width: 300px;
    }
    &.small {
      width: 20px;
    }
  }
  tr {
    td {
      border: none;
      width: 200px;
      &.small {
        width: 20px;
        input {
          padding-left: 0.15rem;
          padding-right: 0.15rem;
        }
      }

      a:hover {
        border-bottom: 2px solid $under;
      }
      span {
        padding-right: 0.5em;
      }
      input {
        border: 2px solid black;
        margin-right: 0.5em;
        text-align: center;
        height: 40px;
        &[disabled='disabled'] {
          text-align: left;
        }
      }
    }
  }
}

.expandable-caret {
  // color: #C3D9D8;
  font-size: 0.65em;
  margin-left: 0.5em;
  vertical-align: middle;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0px 0px;
  overflow-x: hidden !important;
  grid-template-areas:
    '🏷️ 🏷️ 🏷️'
    '. . .'
    '. . .'
    '. . .'
    '. . .'
    '. . .'
    '. . .'
    '💸 💸 💸';

  .is-fullwidth:not(.total) {
    width: inherit !important;
  }

  @include tablet-only {
    grid-template-columns: repeat(2, 1fr);
    grid-template-areas:
      '🏷️ 🏷️'
      '. .'
      '. .'
      '. .'
      '. .'
      '. .'
      '. .'
      '. .'
      '. .'
      '💸 💸';
  }
  @include mobile {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      '🏷️'
      '.'
      '.'
      '.'
      '.'
      '.'
      '.'
      '.'
      '.'
      '💸';
  }
}

.grid-container-half {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0px 0px;
  overflow-x: hidden !important;
  grid-template-areas:
    '🏷️ 🏷️'
    '. .'
    '. .'
    '. .'
    '. .'
    '. .'
    '. .'
    '. .'
    '. .'
    '💸 💸';

  .is-fullwidth:not(.total) {
    width: inherit !important;
  }

  @include touch {
    grid-template-columns: repeat(1, 1fr);
    grid-template-areas:
      '🏷️'
      '.'
      '.'
      '.'
      '.'
      '.'
      '.'
      '.'
      '.'
      '💸';
  }
}
.level {
  grid-area: 🏷️;
}
.total {
  grid-column-start: 💸;
  grid-column-end: 💸;
  // grid-area: 💸;
}
</style>
