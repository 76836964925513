<template>
  <span
    :class="[
      'money-string',
      data.className,
      highlight,
      /is-size-[1-7]/.test(data.className) ? '' : 'is-size-6',
    ]"
    :data-key="data.key"
  >
    <money
      v-if="data.attributes && types.includes(data.attributes.type)"
      v-show="false"
      ref="input"
      :value="value"
      :class="['is-string', highlight]"
      v-bind="money"
      :disabled="true"
    />
    {{ data.translate ? $t(stringified) : stringified }}
  </span>
</template>

<script>
import { mapState } from 'vuex';
import { Money } from 'v-money';

export default {
  components: {
    Money,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      text: '',
      money: {
        decimal: ',',
        thousands: ' ',
        suffix: '',
        precision: 2,
        masked: false,
      },
      types: ['number', 'money'],
    };
  },
  computed: {
    ...mapState({
      apiForm: (state) => state.api.form,
    }),
    value() {
      return this.data.key && this.data.value ? this.apiForm[this.data.key] : this.data.text;
    },
    stringified() {
      if (this.text) return this.text.toString();
      if (this.value && typeof this.value !== 'string') return this.value.toString();
      return this.text || this.value || '';
    },
    highlight() {
      let className = '';
      const value = parseInt(this.value);
      switch (this.data?.attributes?.highlight) {
        case 'both':
          if (value < 0) {
            className = 'has-text-danger';
          } else {
            className = 'has-text-success';
          }
          break;
        case 'negative':
          if (value < 0) {
            className = 'has-text-danger';
          }
          break;
        case 'positive':
          if (value > 0) {
            className = 'has-text-success';
          }
          break;
      }
      return className;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.$nextTick(() => {
          this.text = this.$refs?.input?.$el?.value;
        });
      },
    },
  },
  mounted() {
    if (this.data.attributes) {
      switch (this.data.attributes.type) {
        case 'money':
          this.money.suffix = ' kr';
          break;
        default:
          this.money.suffix = this.data.attributes.suffix ? ` ${this.data.attributes.suffix}` : '';
      }
      if (this.money.suffix !== ' kr') this.money.suffix = this.$t(this.money.suffix);
      this.money.precision = parseInt(this.data.attributes.precision) || 0;
    }
  },
};
</script>

<style lang="scss" scope>
.v-money.is-string,
.money-string {
  font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  border: none;
  background: #fff0;
  // width: 100%;
  // color: inherit;
  font-size: inherit;
}
</style>
