<template>
  <div class="modal is-clipped is-active">
    <div class="modal-background" />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ $t(title) }}
        </p>
        <button type="button" class="delete" aria-label="close" @click="close" />
      </header>
      <section class="modal-card-body">
        <slot />
      </section>
      <footer class="modal-card-foot">
        <button type="button" class="button is-primary" @click="save">
          <font-awesome-icon :icon="['far', 'plus']" />
          {{ $t(saveButton) }}
        </button>
        <!-- <button
                    type="button"
                    class="button"
                    @click="cancel">
                    {{ $t(cancelButton) }}
                </button> -->
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    button1: {
      type: Object,
      default: null,
    },
    button2: {
      type: Object,
      default: null,
    },
  },
  computed: {
    saveButton() {
      return this.button1 != null ? this.button1 : 'Spara';
    },
    // cancelButton() {
    //     return (this.button2 != null) ? this.button2 : 'Cancel';
    // }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    save() {
      this.$emit('save');
    },
    // cancel() {
    //     this.$emit('close');
    // }
  },
};
</script>

<style lang="scss" scope>
.modal-background {
  background-color: rgba(10, 10, 10, 0.55) !important;
  backdrop-filter: blur(4px) !important;
}

.modal-card-head,
.modal-card-foot {
  border: none !important;
  background: white !important;
  border-radius: unset !important;
}

.modal-card-head {
  padding: 3em 3em 0 !important;
  .delete {
    background-color: transparent !important;
    &::after,
    &::before {
      background-color: black !important;
    }
  }
}

.modal-card-body {
  padding: 2em 3em 3em !important;
}

.modal-card-title {
  font-weight: bold !important;
}

.modal-card-foot {
  padding: 0 !important;
  button {
    border-radius: unset !important;
    margin: 0 !important;
    padding: 2em !important;
    width: 100% !important;
    svg {
      margin-right: 0.4em !important;
    }
  }
}
</style>
