<template>
  <form :method="data.method" :enctype="data.enctype" :action="data.action">
    {{ data.enctype }}
    <div
      v-for="(field, index) in data.fields"
      :key="field.key + index"
      :class="['field', field.className]"
    >
      <label
        v-if="field.label || field.text"
        :for="'form-field-' + field.key"
        class="label"
      >
        {{ $t(field.label ? field.label : field.text) }}
      </label>
      <div class="control">
        <template
          v-if="
            [
              'input-text',
              'input-number',
              'input-tel',
              'input-email',
              'input-password',
              'input-hidden',
            ].includes(field.type)
          "
        >
          <the-mask
            v-if="getFieldMask(field) !== null"
            :id="'form-field-' + field.key"
            v-model="form[field.key]"
            :mask="getFieldMask(field)"
            :masked="true"
            :type="field.type.replace('input-', '')"
            :name="field.key"
            :class="[
              'input',
              field.className,
              field.error ? 'is-danger' : null,
            ]"
            :placeholder="field.placeholder || getFieldPlaceholder(field)"
            :required="field.required"
            :disabled="field.disabled"
            :value="field.value"
            @keydown="onInput"
            @keypressed="onInput"
            @keyup="onInput"
          />
          <input
            v-else
            :id="'form-field-' + field.key"
            v-model="form[field.key]"
            :type="field.type.replace('input-', '')"
            :name="field.key"
            :class="[
              'input',
              field.className,
              field.error ? 'is-danger' : null,
            ]"
            :placeholder="field.placeholder"
            :required="field.required"
            :disabled="field.disabled"
            :value="field.value"
            @keydown="onInput"
            @keypressed="onInput"
            @keyup="onInput"
          />
        </template>
        <input
          v-else-if="field.type == 'checkbox'"
          :id="'form-field-' + field.key"
          v-model="form[field.key]"
          type="checkbox"
          :name="field.key"
          :class="['input', field.className, field.error ? 'is-danger' : null]"
          :required="field.required"
          :disabled="field.disabled"
          :checked="field.checked"
          :value="field.value"
          :min="field.min"
          :max="field.max"
        />
        <textarea
          v-else-if="field.type == 'textarea'"
          :id="'form-field-' + field.key"
          v-model="form[field.key]"
          :name="field.key"
          :class="['input', field.className, field.error ? 'is-danger' : null]"
          :placeholder="field.placeholder"
          :required="field.required"
          :disabled="field.disabled"
        />
        <select
          v-else-if="field.type == 'select'"
          :id="'form-field-' + field.key"
          v-model="form[field.key]"
          :name="field.key"
          :class="['input', field.className, field.error ? 'is-danger' : null]"
          :required="field.required"
          :disabled="field.disabled || mergeOptions(field.options).length <= 1"
          :value="field.value"
        >
          <option
            v-for="(option, optionIndex) in mergeOptions(field.options)"
            :key="optionIndex"
            :value="option.value"
            :selected="option.selected"
            :disabled="option.disabled"
          >
            {{ option.title || option.value }}
          </option>
        </select>
      </div>
      <span
        v-if="field.error"
        class="has-text-danger"
        style="float: right; font-size: 0.85rem"
        >{{ field.error }}</span
      >
    </div>
    <div v-if="data.button" class="field">
      <button type="submit" class="button is-primary" @click="send">
        {{ $t(data.button) }}
      </button>
    </div>
  </form>
</template>

<script>
import { mapActions } from 'vuex';
import { TheMask } from 'vue-the-mask';
import { mergeOptions } from '../helpers';

export default {
  components: {
    TheMask,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    sending: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {
      form: {},
    };
  },
  methods: {
    mergeOptions,
    ...mapActions('api', {
      postForm: 'post',
    }),
    onInput(e) {
      if (e.key == 'Enter') {
        e.preventDefault();
        // this.send()
      }
    },
    send() {
      // debugger;
      const maskedFields = this.data.fields.reduce((acc, field) => {
        const mask = this.getFieldMask(field);
        if (mask !== null) {
          acc[field.key] = mask
            .reduce((acc, mask) => {
              acc.push(mask.replace(/#/g, '\\d'));
              return acc;
            }, [])
            .sort((a, b) => b.length - a.length);
        }
        return acc;
      }, {});

      let data = {};
      for (const [key, value] of Object.entries(this.form)) {
        console.log(key, value);
        if (key in maskedFields) {
          for (const mask of maskedFields[key]) {
            const val = value.match(mask)?.[0];
            if (val) {
              data[key] = val;
              break;
            }
          }
        } else {
          data[key] = value;
        }

        if (!data[key]) continue; // skip empty fields
      }
      data = Object.keys(data).map((key) =>
        key in data ? `${key}=${data[key]}` : null
      );
      data = data.join('&');
      this.postForm({ url: this.data.action, data });
    },
    getFieldMask(field) {
      return field?.attributes?.mask?.split(',') || null;
    },
    getFieldPlaceholder(field) {
      return field?.attributes?.placeholder || null;
    },
  },
  watch: {
    sending() {
      this.send();
    },
    form: {
      immediate: true,
      deep: true,
      handler() {
        this.$emit('input', this.form);
      },
    },
    'data.fields': {
      immediate: true,
      handler(fields) {
        if (!fields) {
          console.warn('No fields found in Form', this.data, this.form);
          return;
        }

        for (let i = 0; i < fields.length; i++) {
          const field = fields[i];
          this.$set(this.form, field.key, field.value);

          if (field.options) {
            const merged = this.mergeOptions(field.options);
            for (let j = 0; j < merged.length; j++) {
              const option = merged[j];
              if (option.selected)
                this.$set(this.form, field.key, option.value);
            }
          }
        }
      },
    },
  },
};
</script>

<style lang="scss" scope>
.input {
  border: 2px solid black;
}
input.text-align-left {
  text-align: left !important;
}
</style>
