<template>
  <fragment v-if="data.options">
    <label v-if="data.text" :for="data.key" class="label">
      {{ $t(data.text) }}
    </label>
    <select
      v-model="value"
      :name="data.key"
      :class="['select', data.className ? data.className : '']"
      :style="data.style"
      :disabled="options.length <= 1"
      @change="onChange"
    >
      <option
        v-for="option in options"
        :key="option.key"
        :name="option.key"
        :value="option.value"
        :selected="option.selected"
        :disabled="option.disabled"
      >
        {{ $t(option.title || option.value) }}
      </option>
    </select>
  </fragment>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { mergeOptions } from '../helpers';

export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      apiForm: (state) => state.api.form,
    }),
    value: {
      set(val) {
        this.setFormField({ key: this.data.key, val });
      },
      get() {
        return this.apiForm[this.data.key];
      },
    },
    options() {
      return mergeOptions(this.data.options);
    },
  },
  methods: {
    mergeOptions,
    ...mapActions('api', {
      setFormField: 'form',
    }),
    width() {
      if (this.data.type.replace('input-', '') == 'hidden') return undefined;
      return `width: ${this.data.width ? this.data.width : '80px'}`;
    },
    onChange() {
      this.$emit('change');
    },
  },
  watch: {
    data: {
      immediate: true,
      handler() {
        for (let j = 0; j < this.options.length; j++) {
          const option = this.options[j];
          if (option.selected) this.value = option.value;
        }
      },
    },
  },
};
</script>

<style lang="scss" scope>
$active: #c3d9d8;

select {
  font-size: 1.2em;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  min-width: 250px;
  border: 2px solid black;
  border-radius: 3px;
  padding: 0.4em 1.6em 0.4em 0.5em;
  // margin-left: -0.4em;
  // margin-top: 1em;
  background: white;
  appearance: none;
  outline: none;
  cursor: pointer;
  background-image: url('../assets/images/halfcircle.png');
  background-size: 15px;
  background-position: right 10px top 50%;
  background-repeat: no-repeat;
  option:checked {
    background: $active;
    font-weight: bold;
  }
}

label.rows {
  display: block;
}
</style>
