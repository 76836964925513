<template>
  <!-- <main-layout parent="true"> -->
  <div class="has-text-centered">
    <!-- Upload box -->
    <div class="box">
      <div class="field">
        <label for="file" class="label">
          {{ $t('Välj fil') }}
        </label>
        <div class="control">
          <input
            id="file"
            type="file"
            name="file"
            accept=".csv"
            @change="handleFileChange"
            :class="['input']"
          />
        </div>
      </div>
      <div class="field">
        <label class="label">
          {{ $t('Skicka e-post till nya användare') }}
        </label>
        <div class="control is-small">
          <input
            type="checkbox"
            name="sendEmail"
            v-model="sendEmail"
            :class="['input', 'is-small']"
          />
        </div>
      </div>
      <div class="field">
        <div class="control">
          <button
            type="submit"
            :disabled="!file || !canSubmit"
            :class="['button', 'is-primary']"
            @click="handleSubmit"
          >
            {{ $t('Starta import') }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- </main-layout> -->
</template>

<script>
import { mapActions } from 'vuex';

export default {
  components: {},
  data() {
    return {
      file: null,
      submitted: false,
      sendEmail: true,
    };
  },
  computed: {
    canSubmit() {
      return !!this.file;
    },
  },
  methods: {
    ...mapActions({
      importUsersAndCompanies: 'api/importUsersAndCompanies',
    }),
    handleFileChange(e) {
      this.file = e.target.files[0];
    },
    handleSubmit() {
      // this.submitted = true;
      console.log('handleSubmit', {
        file: this.file,
        sendEmail: this.sendEmail,
      });
      this.importUsersAndCompanies({
        file: this.file,
        sendEmail: this.sendEmail,
      });
    },
  },
};
</script>
