<template>
  <main-layout :parent="true">
    <div class="tabs">
      <ul>
        <li
          v-for="tab in tabs"
          :key="tab.index"
          :class="[
            $route.path == '/settings/' + tab.path ||
            (tab.path != '' && $route.path.startsWith('/settings/' + tab.path))
              ? 'is-active'
              : '',
          ]"
        >
          <router-link :to="'/settings/' + tab.path">
            <span class="icon is-small"><font-awesome-icon :icon="tab.image" /></span>
            <span>{{ $t(tab.title) }}</span>
          </router-link>
        </li>
      </ul>
    </div>
    <router-view />
  </main-layout>
</template>

<script>
import MainLayout from '../layout/Main.vue';

export default {
  components: {
    MainLayout,
  },
  data() {
    return {
      tabs: [
        { path: '', image: ['far', 'user'], title: 'Användare' },
        {
          path: 'logotypes/',
          image: ['far', 'image'],
          title: 'Logotyp',
        },
        {
          path: 'basic-data/',
          image: ['far', 'database'],
          title: 'Global Grunddata',
        },
        {
          path: 'key-figures/',
          image: ['far', 'key'],
          title: 'Nyckeltal',
        },
        {
          path: 'comments/',
          image: ['far', 'life-ring'],
          title: 'Hjälpkommentarer',
        },
      ],
    };
  },
};
</script>
<style lang="scss" scoped></style>
