<template>
  <fragment>
    <h1 v-if="data.title" class="title is-size-3">
      {{ $t(data.title) }}
    </h1>
    <h2 v-else-if="data.subtitle" class="subtitle">
      {{ $t(data.subtitle) }}
    </h2>
    <Button v-else-if="hasButton" :data="data.button" />
  </fragment>
</template>

<script>
import Button from './Button.vue';

export default {
  components: {
    Button,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  computed: {
    hasButton() {
      return !!this.data.button?.text;
    },
  },
};
</script>

<style lang="scss" scope></style>
