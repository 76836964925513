<template>
  <span
    v-if="data.text || data.value == 'circle-info'"
    :class="['icon', data.className ? data.className : '']"
    :data-balloon-length="balloon_length"
    :aria-label="data.text"
    data-balloon-pos="up"
  >
    <font-awesome-icon :icon="data.value" />
  </span>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  computed: {
    balloon_length() {
      const length = this.data.text?.length;
      switch (true) {
        case length < 9:
          return 'small';
        case length < 40:
          return 'medium';
        case length < 80:
          return 'large';
        default:
          return 'xlarge';
      }
    },
  },
};
</script>

<style lang="scss" scope></style>
