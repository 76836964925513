<template>
  <section class="hero is-light is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-7-tablet is-5-desktop is-4-widescreen">
            <!-- Reset password -->
            <h2 class="title has-text-centered">
              {{ $t('Återställ lösenord') }}
            </h2>
            <Alert @alert="submitted = false" />
            <template v-if="token">
              <p v-if="tokenError" class="has-text-danger">
                {{ $t(tokenError) }}
              </p>
              <form
                v-else
                class="box"
                @submit.prevent="handleChangePasswordSubmit"
              >
                <div class="field">
                  <label for="password" class="label">
                    {{ $t('Lösenord') }}
                  </label>
                  <div class="control has-icons-left">
                    <input
                      id="password"
                      v-model="form.confirmPassword"
                      v-validate="'required'"
                      type="password"
                      name="password"
                      :class="[
                        'input',
                        submitted && errors.has('password') ? 'is-danger' : '',
                      ]"
                    />
                    <span class="icon is-small is-left">
                      <font-awesome-icon icon="lock" />
                    </span>
                  </div>
                </div>
                <div class="field">
                  <label for="confirmPassword" class="label">
                    {{ $t('Bekräfta Lösenord') }}
                  </label>
                  <div class="control has-icons-left">
                    <input
                      id="confirmPassword"
                      v-model="form.password"
                      v-validate="'required'"
                      type="password"
                      name="confirmPassword"
                      :class="[
                        'input',
                        submitted && errors.has('confirmPassword')
                          ? 'is-danger'
                          : '',
                      ]"
                    />
                    <span class="icon is-small is-left">
                      <font-awesome-icon icon="lock" />
                    </span>
                  </div>
                </div>
                <div class="field has-text-centered">
                  <button
                    type="submit"
                    :disabled="submitted || !canSubmit"
                    :class="[
                      'button',
                      'is-primary',
                      status.loggingIn ? 'is-loading' : '',
                    ]"
                  >
                    {{ $t('Återställ lösenord') }}
                  </button>
                </div>
              </form>
            </template>
            <!-- Request password reset -->
            <template v-else>
              <form class="box" @submit.prevent="handleResetPasswordSubmit">
                <div class="field">
                  <label for="username" class="label">
                    {{ $t('E-post') }}
                  </label>
                  <div class="control has-icons-left">
                    <input
                      id="username"
                      v-model="form.email"
                      v-validate="'required'"
                      type="email"
                      name="username"
                      :class="[
                        'input',
                        submitted && errors.has('username') ? 'is-danger' : '',
                      ]"
                    />
                    <span class="icon is-small is-left">
                      <font-awesome-icon icon="envelope" />
                    </span>
                  </div>
                </div>
                <div class="field has-text-centered">
                  <button
                    type="submit"
                    :disabled="submitted || !canSubmit"
                    :class="[
                      'button',
                      'is-primary',
                      submitted ? 'is-loading' : '',
                    ]"
                  >
                    {{ $t('Skicka återställningslänk') }}
                  </button>
                </div>
              </form>
            </template>
            <div v-if="!user" class="has-text-centered">
              <router-link to="/login" class="button is-text">
                {{ $t('Logga in') }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Alert from '../components/Alert.vue';

import { mapState, mapActions } from 'vuex';
import { userService } from '../services';

export default {
  components: {
    Alert,
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
        confirmPassword: '',
      },
      tokenError: '',
      submitted: false,
    };
  },
  computed: {
    ...mapState('account', ['status', 'user']),
    token() {
      return this.$route.query.token;
    },
    canSubmit() {
      if (this.token) {
        return this.form.password && this.form.confirmPassword;
      } else {
        return !!this.form.email;
      }
    },
  },
  methods: {
    ...mapActions('account', ['resetPassword', 'changePassword']),
    handleResetPasswordSubmit() {
      this.submitted = true;
      this.resetPassword(this.form);
    },
    handleChangePasswordSubmit() {
      this.submitted = true;
      this.changePassword({ token: this.token, ...this.form });
    },
  },
  watch: {
    token: {
      immediate: true,
      handler() {
        this.form.password = '';
        this.form.confirmPassword = '';
        this.tokenError = ' ';

        if (!this.token) return;

        userService
          .validateResetToken(this.token)
          .then(() => {
            this.tokenError = '';
          })
          .catch(() => {
            this.tokenError =
              'Återställningslänken är inte giltig eller har redan använts.';
          });
      },
    },
  },
};
</script>


<style lang="scss" scoped>
.field.has-text-centered button {
  padding: 1.8em 4em;
  border: none;
}
</style>
