<template>
  <main-layout parent="true">
    <div class="has-text-centered">
      <img src="@/assets/images/nbf.png" width="100" height="100" />
      <h2 class="title">404</h2>
      <h1 class="calc-subtitle">
        {{ $t('Sida kunde inte hittas') }}
      </h1>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from '../layout/Main.vue';

export default {
  components: {
    MainLayout,
  },
};
</script>
