<template>
  <span :class="data.className">{{ moment(data.text).format('lll') }}</span>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="scss" scope></style>
