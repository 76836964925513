import Vue from 'vue';
import Router from 'vue-router';

import NotFound from '../pages/404.vue';
import Unauthorized from '../pages/401.vue';
// import ApiPage from '../pages/ApiPage.vue'
import ApiPage from '../pages/ApiPage.vue';
import ImportPage from '../pages/ImportPage.vue';
import Settings from '../pages/Settings.vue';
import Calculation from '../pages/Calculation.vue';
// import Users from '../pages/settings/Users.vue'
import Login from '../pages/Login.vue';
// import Register from '../pages/Register.vue';
import ResetPassword from '../pages/ResetPassword.vue';
import LogotypesUpload from '../pages/settings/LogotypesUpload.vue';

import i18n from '../languages/i18n.js'

Vue.use(Router);

export const router = new Router({
  mode: 'history',
  linkExactActiveClass: 'is-active',
  routes: [
    /* {
            path: '/',
            component: ApiPage,
            props: { api: '/calculations', parent: true },
            meta: {
                title: 'MRF - Kalkyl'
            }
        }, */
    {
      path: '/',
      component: ApiPage,
      props: { api: '/calculations', parent: true },
      meta: {
        title: 'NBF - Timeselvkostmodell.',
      },
    },
    /* {
            path: '/basic-data',
            component: ApiPage,
            props: { api: '/basic-data/', parent: true },
            meta: {
                title: 'MRF - Kalkyl'
            }
        },
        {
            path: '/basic-data/:id',
            component: ApiPage,
            props: { api: '/view/basic-data/%id', parent: true },
            meta: {
                title: 'MRF - Kalkyl'
            }
        }, */
    {
      path: '/calculation/:id(\\w+)',
      component: Calculation,
      children: [
        {
          path: '',
          component: ApiPage,
          props: { api: '/view/calculation/%id' },
          meta: {
            title: 'Timeselvkostmodell - Grunddata',
            tab: '',
          },
        },
        {
          path: 'employees',
          component: ApiPage,
          props: { api: '/view/calculation/%id' },
          // props: { api: '/view/calculation/%id/employees' },
          meta: {
            title: 'Timeselvkostmodell - Anställda',
            tab: 'employees',
          },
        },
        {
          path: 'employees/:eid(\\w+)',
          component: ApiPage,
          props: { api: '/view/calculation/%id/employee/%eid' },
          meta: {
            title: 'Timeselvkostmodell - Anställd',
            tab: 'employee',
            activeTab: 'employees',
            backUrl: 'employees',
            // promptUnsavedChanges: true,
          },
        },
        {
          path: 'costs',
          component: ApiPage,
          props: { api: '/view/calculation/%id' },
          // props: { api: '/view/calculation/%id/costs' },
          meta: {
            title: 'Timeselvkostmodell - Kostnader',
            tab: 'costs',
          },
        },
        {
          path: 'amortization',
          component: ApiPage,
          props: { api: '/view/calculation/%id' },
          // props: { api: '/view/calculation/%id/amortization' },
          meta: {
            title: 'Timeselvkostmodell - Avskrivningar',
            tab: 'amortization',
          },
        },
        {
          path: 'departments',
          component: ApiPage,
          props: { api: '/view/calculation/%id' },
          // props: { api: '/view/calculation/%id/departments' },
          meta: {
            title: 'Timeselvkostmodell - Fördelning',
            tab: 'departments',
          },
        },
        {
          path: 'summary',
          component: ApiPage,
          props: { api: '/view/calculation/%id' },
          // props: { api: '/view/calculation/%id/summary' },
          meta: {
            title: 'Timeselvkostmodell - Sammanfattning',
            tab: 'summary',
          },
        },
        {
          path: 'budget',
          component: ApiPage,
          props: { api: '/view/calculation/%id' },
          // props: { api: '/view/calculation/%id/budget' },
          meta: {
            title: 'Timeselvkostmodell - Budget',
            tab: 'budget',
          },
        },
      ],
    },
    {
      path: '/settings',
      component: Settings,
      children: [
        {
          path: '',
          // component: Users,
          component: ApiPage,
          props: { api: '/settings/' },
          meta: {
            title: 'Timeselvkostmodell - Användare',
          },
        },
        {
          path: 'companies/:id',
          component: ApiPage,
          props: { api: '/settings/companies/%id' },
          meta: {
            title: 'Timeselvkostmodell - Företag',
          },
        },
        {
          path: 'users/:id',
          component: ApiPage,
          props: { api: '/settings/users/%id' },
          meta: {
            title: 'Timeselvkostmodell - Användare',
          },
        },
        {
          path: 'logotypes',
          component: ApiPage,
          props: { api: '/settings/logotypes' },
          meta: {
            title: 'Timeselvkostmodell - Logotyper',
          },
          name: 'logotypes',
        },
        {
          path: 'logotypes/:id',
          component: LogotypesUpload,
          props: { api: '/settings/logotypes/upload' },
          meta: {
            title: 'Timeselvkostmodell - Logotyper > Ladda upp',
          },
        },
        /* {
                    path: 'basic-data',
                    component: ApiPage,
                    props: { api: '/settings/basic-data' },
                    meta: {
                        title: 'Kalkyl - Global Grunddata'
                    }
                }, */
        {
          path: 'basic-data',
          component: ApiPage,
          props: { api: '/settings/basic-data' },
          meta: {
            title: 'NBF - Timeselvkostmodell',
          },
        },
        {
          path: 'basic-data/:id',
          component: ApiPage,
          props: { api: '/view/basic-data/%id' },
          meta: {
            title: 'NBF - Timeselvkostmodell',
          },
        },
        {
          path: 'key-figures',
          component: ApiPage,
          props: { api: '/settings/key-figures' },
          meta: {
            title: 'Timeselvkostmodell - Nyckeltal',
          },
        },
        {
          path: 'comments',
          component: ApiPage,
          props: { api: '/settings/comments' },
          // props: { api: '/view/comments' },
          meta: {
            title: 'Timeselvkostmodell - Hjälpkommentarer',
          },
        },
        {
          path: 'import',
          component: ImportPage,
          props: { api: '/settings/import' },
          meta: {
            title: 'Timeselvkostmodell - Import',
          },
        },
      ],
    },
    {
      path: '/login',
      component: Login,
      meta: {
        title: 'Timeselvkostmodell - Logga in',
        metaTags: [
          {
            name: 'description',
            content: 'Att beräkna timdebitering blir aldrig enklare.',
          },
        ],
      },
    },
    {
      path: '/reset-password',
      component: ResetPassword,
      meta: {
        title: 'Timeselvkostmodell - Återställ lösenord',
      },
    },
    // {
    //   path: '/register',
    //   component: Register,
    //   meta: {
    //     title: 'Kalkyl - Register',
    //   },
    // },
    {
      path: '/logout',
      redirect: '/login',
    },
    {
      path: '/401',
      component: Unauthorized,
      meta: {
        title: '401 - Timeselvkostmodell',
      },
    },
    {
      path: '*',
      component: NotFound,
      meta: {
        title: '404 - Timeselvkostmodell',
      },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const publicPages = ['/login', '/register', '/reset-password'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  if (authRequired && !loggedIn) {
    return next('/login');
  }

  if (to.redirectedFrom == '/logout') {
    Vue.dialog
      .confirm(i18n.t('Do you really want to leave?'), {
        okText: i18n.t('Fortsätt'),
        cancelText: i18n.t('Avbryt'),
      })
      .then(() => {
        next();
      })
      .catch(() => {
        next(false);
      });
    return;
  }

  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);

  if (nearestWithTitle) document.title = nearestWithTitle.meta.title;
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map((el) =>
    el.parentNode.removeChild(el)
  );
  if (!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement('meta');
      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });
      tag.setAttribute('data-vue-router-controlled', '');
      return tag;
    })
    .forEach((tag) => document.head.appendChild(tag));

  next();
});
