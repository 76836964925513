<template>
  <div>
    <ul>
      <li v-for="l in list" :key="l.title">
        <router-link v-if="data.type.endsWith('link')" :to="l.link">
          {{ data.translate ? $t(l.title) : l.title }}
        </router-link>
        <template v-else>
          {{ data.translate ? $t(l.title) : l.title }}
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
import { mergeOptions } from '../helpers';

export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    list() {
      return this.data?.options?.list || [];
    },
  },
  watch: {},
  methods: {
    mergeOptions,
  },
};
</script>
