import Vue from 'vue';
import i18n from './languages/i18n';
import VeeValidate from 'vee-validate';
import Fragment from 'vue-fragment';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSpinner,
  faEnvelope,
  faLock,
  faUser,
  faUsers,
  faPlus,
  faImage,
  faKey,
  faDatabase,
  faLifeRing,
  faDollarSign,
  faFileInvoiceDollar,
  faShareAltSquare,
  faFileAlt,
  faHandHoldingUsd,
  faSignOutAlt,
  faInfoCircle,
  faUpload,
  faUnlink,
  faEdit,
  fas,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import FlagIcon from 'vue-flag-icon';
import Buefy from 'buefy';
import 'buefy/dist/buefy.css';
import VuejsDialog from 'vuejs-dialog';
import 'vuejs-dialog/dist/vuejs-dialog.min.css';

import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';

import moment from 'moment';
import TypeLoader from './components/TypeLoader.vue';
import { router } from './helpers';
import { store } from './store';
import App from './App.vue';

Vue.use(VeeValidate);
Vue.use(Buefy);
Vue.use(VuejsDialog);
Vue.use(FlagIcon);
Vue.use(Fragment.Plugin);
Vue.prototype.moment = moment;
moment.locale('sv');

library.add(
  faSpinner,
  faEnvelope,
  faLock,
  faUser,
  faUsers,
  faPlus,
  faImage,
  faKey,
  faDatabase,
  faLifeRing,
  faDollarSign,
  faFileInvoiceDollar,
  faShareAltSquare,
  faFileAlt,
  faHandHoldingUsd,
  faSignOutAlt,
  faInfoCircle,
  fal,
  faUpload,
  far,
  fas,
  faUnlink,
  faEdit
);

Vue.component('FontAwesomeIcon', FontAwesomeIcon);
Vue.component('TypeLoader', TypeLoader);

Vue.component('Xfragment', {
  functional: true,
  render(h, context) {
    const dom = h('div', context.data, context.children);
    return dom.children || [];
  },
});

Vue.config.productionTip = process.env.NODE_ENV === 'development';

if (process.env.VUE_APP_ENV && process.env.VUE_APP_ENV !== 'development') {
  Sentry.init({
    environment: process.env.VUE_APP_ENV,
    dsn: 'https://826a63a542474954b25a04f80a0a32d8@sentry.duva.se/45',
    integrations: [
      new Integrations.BrowserTracing(),
      new VueIntegration({
        Vue,
        attachProps: true,
        tracing: true,
        tracingOptions: { trackComponents: true },
      }),
    ],
    tracesSampleRate: 0.1,
    beforeSend(e) {
      // Filter NavigationDuplicated errors from VueRouter
      for (const exception in e.exception.values) {
        if (exception.type === 'NavigationDuplicated') return null;
      }

      return e;
    },
  });
}

// import { configureFakeBackend } from './helpers';
// configureFakeBackend();

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
