import { render, staticRenderFns } from "./Permissions.vue?vue&type=template&id=9616ccf0&scoped=true&"
import script from "./Permissions.vue?vue&type=script&lang=js&"
export * from "./Permissions.vue?vue&type=script&lang=js&"
import style0 from "./Permissions.vue?vue&type=style&index=0&id=9616ccf0&prod&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9616ccf0",
  null
  
)

export default component.exports