<template>
  <div class="permissions">
    <h2>{{ $t('Kopplade Företag') }}</h2>
    <basic-select
      :options="select"
      :selected-option="selectValue"
      :is-disabled="select.length === 0"
      :placeholder="select.length === 0 ? `-- ${$t('Inga företag')} --` : `-- ${$t('Välj')} --`"
      @select="selectValue = $event"
    />
    <ul>
      <li v-for="l in list" :key="l.title">
        <span>{{ data.translate ? $t(l.title) : l.title }}</span>
        <a @click="remove(l.value)"><font-awesome-icon icon="backspace" /></a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { BasicSelect } from 'vue-search-select';
import 'vue-search-select/dist/VueSearchSelect.css';

export default {
  components: {
    BasicSelect,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      apiForm: (state) => state.api.form,
      oldApiForm: (state) => state.api.oldForm,
    }),
    select() {
      return (
        this.data?.options?.select.reduce((arr, opt) => {
          arr.push({
            text: opt.title || opt.value,
            value: opt,
          });
          return arr;
        }, []) || []
      );
    },
    list() {
      return this.data?.options?.list || [];
    },
    value: {
      set(val) {
        const v = JSON.stringify(val);
        if (this.apiForm[this.data.key] != v) {
          this.setFormField({ key: this.data.key, val: v });
          this.onChange();
        }
      },
      get() {
        return this.apiForm[this.data.key] ? JSON.parse(this.apiForm[this.data.key]) : {};
      },
    },
    selectValue: {
      set(opt) {
        if (this.data?.options?.list.findIndex((l) => l.value === opt.value.value) === -1) {
          this.data?.options?.list.push(opt.value);
        }
      },
      get() {
        return {};
      },
    },
  },
  watch: {
    list: {
      immediate: true,
      handler(opt) {
        this.value = this.convertToCompanies(opt);
      },
    },
  },
  methods: {
    ...mapActions('api', {
      setFormField: 'form',
    }),
    convertToCompanies(val) {
      const companies = [];
      if (!val) return companies;

      for (const company of val) {
        companies.push({
          id: company.value,
          name: company.title,
          permissions: ['create', 'read', 'update', 'delete'],
        });
      }

      return companies;
    },
    remove(val) {
      if (!this.data?.options?.list) return;
      this.data.options.list = this.data.options.list.filter((l) => l.value !== val);
    },
    onChange() {
      this.$emit('change', {
        key: this.data.key,
        value: JSON.stringify(this.value),
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.search.selection
    border: 2px solid !important
    ::v-deep .menu
        margin-left: -2px
        border-width: 2px
        border-color: black !important
.permissions ::v-deep .search.selection .dropdown.icon
    background-image: url(/img/halfcircle.20867b7a.png)
    background-size: 15px
    background-position: right 10px top 50%
    background-repeat: no-repeat

ul
    min-height: 10em
    min-width: 250px
    padding: 0.25em
    margin-top: 0.5em
    border: 2px solid
    border-radius: 4px
ul li
    display: flex
    justify-content: space-between
    border-top: 2px solid
    padding: 0.25em
    &:first-of-type
        border-top: none
    &:last-of-type
        border-bottom: 2px solid
</style>
