var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"hero is-light is-fullheight"},[_c('div',{staticClass:"hero-body"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-7-tablet is-5-desktop is-4-widescreen"},[_c('h2',{staticClass:"title has-text-centered"},[_vm._v(" "+_vm._s(_vm.$t('Återställ lösenord'))+" ")]),_c('Alert',{on:{"alert":function($event){_vm.submitted = false}}}),(_vm.token)?[(_vm.tokenError)?_c('p',{staticClass:"has-text-danger"},[_vm._v(" "+_vm._s(_vm.$t(_vm.tokenError))+" ")]):_c('form',{staticClass:"box",on:{"submit":function($event){$event.preventDefault();return _vm.handleChangePasswordSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label",attrs:{"for":"password"}},[_vm._v(" "+_vm._s(_vm.$t('Lösenord'))+" ")]),_c('div',{staticClass:"control has-icons-left"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.confirmPassword),expression:"form.confirmPassword"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
                      'input',
                      _vm.submitted && _vm.errors.has('password') ? 'is-danger' : '',
                    ],attrs:{"id":"password","type":"password","name":"password"},domProps:{"value":(_vm.form.confirmPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "confirmPassword", $event.target.value)}}}),_c('span',{staticClass:"icon is-small is-left"},[_c('font-awesome-icon',{attrs:{"icon":"lock"}})],1)])]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label",attrs:{"for":"confirmPassword"}},[_vm._v(" "+_vm._s(_vm.$t('Bekräfta Lösenord'))+" ")]),_c('div',{staticClass:"control has-icons-left"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.password),expression:"form.password"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
                      'input',
                      _vm.submitted && _vm.errors.has('confirmPassword')
                        ? 'is-danger'
                        : '',
                    ],attrs:{"id":"confirmPassword","type":"password","name":"confirmPassword"},domProps:{"value":(_vm.form.password)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "password", $event.target.value)}}}),_c('span',{staticClass:"icon is-small is-left"},[_c('font-awesome-icon',{attrs:{"icon":"lock"}})],1)])]),_c('div',{staticClass:"field has-text-centered"},[_c('button',{class:[
                    'button',
                    'is-primary',
                    _vm.status.loggingIn ? 'is-loading' : '',
                  ],attrs:{"type":"submit","disabled":_vm.submitted || !_vm.canSubmit}},[_vm._v(" "+_vm._s(_vm.$t('Återställ lösenord'))+" ")])])])]:[_c('form',{staticClass:"box",on:{"submit":function($event){$event.preventDefault();return _vm.handleResetPasswordSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label",attrs:{"for":"username"}},[_vm._v(" "+_vm._s(_vm.$t('E-post'))+" ")]),_c('div',{staticClass:"control has-icons-left"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.email),expression:"form.email"},{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],class:[
                      'input',
                      _vm.submitted && _vm.errors.has('username') ? 'is-danger' : '',
                    ],attrs:{"id":"username","type":"email","name":"username"},domProps:{"value":(_vm.form.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "email", $event.target.value)}}}),_c('span',{staticClass:"icon is-small is-left"},[_c('font-awesome-icon',{attrs:{"icon":"envelope"}})],1)])]),_c('div',{staticClass:"field has-text-centered"},[_c('button',{class:[
                    'button',
                    'is-primary',
                    _vm.submitted ? 'is-loading' : '',
                  ],attrs:{"type":"submit","disabled":_vm.submitted || !_vm.canSubmit}},[_vm._v(" "+_vm._s(_vm.$t('Skicka återställningslänk'))+" ")])])])],(!_vm.user)?_c('div',{staticClass:"has-text-centered"},[_c('router-link',{staticClass:"button is-text",attrs:{"to":"/login"}},[_vm._v(" "+_vm._s(_vm.$t('Logga in'))+" ")])],1):_vm._e()],2)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }