<template>
  <main-layout :parent="false">
    <h1 class="title">
      {{ $t('Ladda upp bild') }}
    </h1>
    <form enctype="multipart/form-data" :action="api" method="post" @submit="submitFile($event)">
      <div class="field">
        <div :class="['file is-large is-boxed is-centered', file != null ? ' has-name' : '']">
          <label class="file-label">
            <input
              id="file"
              ref="file"
              type="file"
              name="file"
              class="file-input"
              @change="handleFileUpload()"
            />
            <span class="file-cta">
              <span class="file-icon">
                <font-awesome-icon icon="image" />
              </span>
              <span class="file-label">
                {{ $t('Välj fil...') }}
              </span>
            </span>
            <span v-if="file != null" class="file-name">
              {{ file != null ? file.name : '' }}
            </span>
          </label>
        </div>
      </div>
      <div class="field is-grouped is-grouped-centered">
        <div class="control">
          <button
            type="submit"
            :class="['button is-medium is-primary', uploading ? ' is-loading' : '']"
            :disabled="file == null || uploading"
          >
            <span class="file-icon">
              <font-awesome-icon icon="upload" />
            </span>
            <span>{{ $t('Ladda upp') }}</span>
          </button>
        </div>
      </div>
    </form>
  </main-layout>
</template>

<script>
import { mapActions } from 'vuex';
import MainLayout from '../../layout/Main.vue';
import { postData } from '../../services';

export default {
  components: {
    MainLayout,
  },
  props: {
    api: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      file: null,
      uploading: false,
    };
  },
  methods: {
    ...mapActions({
      alertError: 'alert/error',
      clearAlert: 'alert/clear',
    }),
    handleFileUpload() {
      this.file = this.$refs.file.files[0];
    },
    submitFile() {
      if (!this.file) this.alertError('Choose file', { root: true });
      event.preventDefault();
      this.uploading = true;

      const formData = new FormData();
      formData.append('id', this.$route.params.id);
      formData.append('image', this.file);

      const headers = { 'Content-Type': 'multipart/form-data' };
      postData(this.api, formData, headers).then((response) => {
        this.uploading = false;
        if (response.status != 'OK') {
          this.alertError(response.message);
          this.file = null;
        } else {
          this.clearAlert();
          this.$router.push({
            name: 'logotypes',
            params: { refresh: true },
          });
        }
      });
    },
  },
};
</script>
