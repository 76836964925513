<template>
  <div class="information-box">
    <h3>
      {{ data.title.translate ? $t(data.title.text) : data.title.text }}
    </h3>
    <p v-for="(p, pIndex) in data.columns" :key="'bodyp' + pIndex + pIndex" :class="p.className">
      <TypeLoader
        v-for="(item, index) in p.fields"
        :key="item.type + 'p' + pIndex + index"
        :data="item"
      />
    </p>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {},
  mounted() {},
};
</script>

<style lang="scss" scope>
.information-box {
  background: #c3d9d8;
  padding: 2rem;
}

.information-box h3 {
  font-size: 1.25rem;
}
</style>
