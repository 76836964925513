<template>
  <fragment v-if="data">
    <Section v-if="data.views" :data="data" />
    <Level v-else-if="data.type == 'level'" :data="data" />
    <Heading v-else-if="data.type == 'heading'" :data="data" />
    <Table v-else-if="data.type && data.type.startsWith('table')" :data="data" />
    <Button v-else-if="data.type && data.type.startsWith('button')" :data="data" />
    <Form v-else-if="data.type == 'form'" :data="data" :sending="sending" />
    <StringText v-else-if="data.type && data.type.match('string')" :data="data" />
    <StringMulti v-else-if="data.type && data.type.match('multi')" :data="data" />
    <DateText v-else-if="data.type == 'date'" :data="data" />
    <Link v-else-if="data.type == 'link'" :data="data" />
    <RouterLink v-else-if="data.type == 'router-link'" :data="data" />
    <Notification v-else-if="data.type == 'notification'" :data="data" />
    <Icon v-else-if="data.type == 'icon'" :data="data" />
    <!-- <InputPercent
            v-else-if="data.type && data.type == 'input-percent'"
            :data="data"
            @change="refreshCalculation"
            @blur="onBlur" /> -->
    <!-- <InputTime
            v-else-if="data.type && (data.type == 'input-time')"
            :data="data"
            @change="refreshCalculation"
            @blur="onBlur" /> -->
    <Input
      v-else-if="data.type && data.type.startsWith('input-')"
      :data="data"
      @change="refreshCalculation"
      @blur="onBlur"
    />
    <Select
      v-else-if="data.type == 'select'"
      :data="data"
      @change="refreshCalculation($event, false)"
    />
    <ImageFile v-else-if="data.type == 'image'" :data="data" />
    <InformationBox v-else-if="data.type == 'information'" :data="data" />
    <Permissions
      v-else-if="data.type == 'permissions'"
      :data="data"
      @change="refreshCalculation($event, false)"
    />
    <List v-else-if="data.type && data.type.startsWith('list')" :data="data" />
  </fragment>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Section from './Section.vue';
import Level from './Level.vue';
import Heading from './Heading.vue';
import Table from './Table.vue';
import Button from './Button.vue';
import Form from './Form.vue';
import StringText from './String.vue';
import StringMulti from './StringMulti.vue';
import DateText from './Date.vue';
import Link from './Link.vue';
import RouterLink from './RouterLink.vue';
import Notification from './Notification.vue';
import Icon from './Icon.vue';
import Input from './Input.vue';
// import InputPercent from './InputPercent.vue';
// import InputTime from './InputTime.vue';
import Select from './Select.vue';
import ImageFile from './Image.vue';
import InformationBox from './InformationBox.vue';
import Permissions from './Permissions.vue';
import List from './List.vue';
// import _ from 'lodash'

export default {
  components: {
    Section,
    Level,
    Heading,
    Table,
    Button,
    Form,
    StringText,
    StringMulti,
    DateText,
    Link,
    RouterLink,
    Notification,
    Icon,
    Input,
    // InputPercent,
    // InputTime,
    Select,
    ImageFile,
    InformationBox,
    Permissions,
    List,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    sending: {
      type: Boolean,
      default: null,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      form: (state) => state.api.form,
      formPath: (state) => state.api.formPath,
    }),
  },
  mounted() {
    // if(this.data.key && this.data.value) {
    //     this.setFormField({key: this.data.key, val: this.data.value})
    // }
  },
  methods: {
    ...mapActions('api', {
      // setFormField: 'form',
      postForm: 'post',
    }),
    onBlur(e) {
      this.$emit('blur', e);
    },
    refreshCalculation(e, debounce = true) {
      this.$emit('change', e);
      if (this.data.ignored) return;
      let data = this.form;
      if (e.key in data) data[e.key] = e.value;
      data = Object.keys(data).map((key) => `${key}=${data[key]}`);
      data = data.join('&');
      this.postForm({
        url: this.formPath,
        data,
        redirect: false,
        debounce,
      });
      // console.time("auto_refresh")
    },
  },
};
</script>
