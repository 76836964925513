<template>
  <fragment>
    <div v-if="alert.message" :class="`notification ${alert.type}`">
      <button class="delete" @click="clearAlert" />
      {{ $t(alert.message) }}
    </div>
  </fragment>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState({
      alert: (state) => state.alert,
    }),
  },
  watch: {
    $route() {
      this.clearAlert();
    },
    'alert.message'() {
      this.$emit('alert', this.alert);
    },
  },
  methods: {
    ...mapActions({
      clearAlert: 'alert/clear',
      tab: 'api/tab',
    }),
  },
};
</script>

<style lang="scss" scope></style>
