var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"hero is-light is-fullheight"},[_c('div',{staticClass:"hero-body"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"columns is-centered"},[_c('div',{staticClass:"column is-5-tablet is-4-desktop is-3-widescreen"},[_vm._m(0),_c('Alert'),_c('form',{staticClass:"box",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"field"},[_c('label',{staticClass:"label",attrs:{"for":"username"}},[_vm._v(" "+_vm._s(_vm.$t('E-post'))+" ")]),_c('div',{staticClass:"control has-icons-left"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.username),expression:"username"}],class:['input', _vm.submitted && !_vm.username ? '' : ''],attrs:{"id":"username","type":"email","name":"username","placeholder":"e.g. John.Smith","required":""},domProps:{"value":(_vm.username)},on:{"input":function($event){if($event.target.composing)return;_vm.username=$event.target.value}}}),_c('span',{staticClass:"icon is-small is-left"},[_c('font-awesome-icon',{attrs:{"icon":"envelope"}})],1)])]),_c('div',{staticClass:"field"},[_c('label',{staticClass:"label",attrs:{"for":"password"}},[_vm._v(" "+_vm._s(_vm.$t('Lösenord'))+" ")]),_c('div',{staticClass:"control has-icons-left"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.password),expression:"password"}],class:[
                    'input',
                    _vm.submitted && !_vm.password ? 'is-danger' : '',
                  ],attrs:{"id":"password","type":"password","name":"password","placeholder":"*******","required":""},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value}}}),_c('span',{staticClass:"icon is-small is-left"},[_c('font-awesome-icon',{attrs:{"icon":"lock"}})],1)]),_c('router-link',{staticStyle:{"float":"right"},attrs:{"to":"/reset-password"}},[_vm._v(" "+_vm._s(_vm.$t('Glömt lösenord'))+" ")])],1),_c('div',{staticClass:"field"},[_c('label',{staticClass:"checkbox",attrs:{"for":"remember"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.remember),expression:"remember"}],attrs:{"id":"remember","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.remember)?_vm._i(_vm.remember,null)>-1:(_vm.remember)},on:{"change":function($event){var $$a=_vm.remember,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.remember=$$a.concat([$$v]))}else{$$i>-1&&(_vm.remember=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.remember=$$c}}}}),_vm._v(" "+_vm._s(_vm.$t('Kom ihåg'))+" ")])]),_c('div',{staticClass:"field has-text-centered"},[_c('button',{class:[
                  'button',
                  'is-primary',
                  _vm.status.loggingIn ? 'is-loading' : '',
                ],attrs:{"type":"submit","disabled":_vm.status.loggingIn}},[_vm._v(" "+_vm._s(_vm.$t('Logga in'))+" ")])])])],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"has-text-centered"},[_c('img',{attrs:{"src":require("@/assets/images/nbf.png"),"width":"100","height":"100"}})])
}]

export { render, staticRenderFns }