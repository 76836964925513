<template>
  <section class="hero is-light is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-5-tablet is-4-desktop is-3-widescreen">
            <div class="has-text-centered">
              <img src="@/assets/images/nbf.png" width="100" height="100" />
            </div>
            <Alert />
            <form class="box" @submit.prevent="handleSubmit">
              <div class="field">
                <label for="username" class="label">
                  {{ $t('E-post') }}
                </label>
                <div class="control has-icons-left">
                  <input
                    id="username"
                    v-model="username"
                    type="email"
                    name="username"
                    placeholder="e.g. John.Smith"
                    :class="['input', submitted && !username ? '' : '']"
                    required
                  />
                  <span class="icon is-small is-left">
                    <font-awesome-icon icon="envelope" />
                  </span>
                </div>
              </div>
              <div class="field">
                <label for="password" class="label">
                  {{ $t('Lösenord') }}
                </label>
                <div class="control has-icons-left">
                  <input
                    id="password"
                    v-model="password"
                    type="password"
                    name="password"
                    placeholder="*******"
                    :class="[
                      'input',
                      submitted && !password ? 'is-danger' : '',
                    ]"
                    required
                  />
                  <span class="icon is-small is-left">
                    <font-awesome-icon icon="lock" />
                  </span>
                </div>
                <router-link to="/reset-password" style="float: right">
                  {{ $t('Glömt lösenord') }}
                </router-link>
              </div>
              <div class="field">
                <label for="remember" class="checkbox">
                  <input id="remember" v-model="remember" type="checkbox" />
                  {{ $t('Kom ihåg') }}
                </label>
              </div>
              <div class="field has-text-centered">
                <button
                  type="submit"
                  :disabled="status.loggingIn"
                  :class="[
                    'button',
                    'is-primary',
                    status.loggingIn ? 'is-loading' : '',
                  ]"
                >
                  {{ $t('Logga in') }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Alert from '../components/Alert.vue';

export default {
  components: {
    Alert,
  },
  data() {
    return {
      username: '',
      password: '',
      remember: false,
      submitted: false,
    };
  },
  computed: {
    ...mapState('account', ['status']),
  },
  watch: {
    status() {
      if (!status.loggingIn) this.password = '';
    },
  },
  created() {
    this.logout();
  },
  methods: {
    ...mapActions({
      clearAlert: 'alert/clear',
      login: 'account/login',
      logout: 'account/logout',
    }),
    handleSubmit() {
      this.submitted = true;
      const { username, password, remember } = this;
      if (username && password) {
        this.clearAlert();
        this.login({ username, password, remember });
      }
    },
  },
};
</script>

<style lang="scss" scope>
.logo {
  margin-bottom: 20px;
}
</style>
