<template>
  <main-layout :parent="true">
    <h1 class="title is-size-4">
      {{ title }}
    </h1>
    <h2 id="kalkyl-id" class="calc-subtitle calc-id secondary-color is-size-6">
      {{ id }}
      <span class="is-size-7 is-block">{{ $route.params.eid }}</span>
    </h2>
    <div class="tabs">
      <ul>
        <li v-for="tab in tabs" :key="tab.index">
          <router-link
            :to="'/calculation/' + $route.params.id + '/' + tab.path"
            :class="($route.meta.activeTab || $route.meta.tab) == tab.path ? 'is-active' : ''"
          >
            <span class="icon is-small"><font-awesome-icon :icon="tab.image" /></span>
            <span>{{ $t(tab.title) }}</span>
          </router-link>
        </li>
      </ul>
    </div>
    <router-view />
    <div
      v-if="data"
      :class="['field', 'has-text-centered', $route.meta.backUrl ? null : 'space-between']"
    >
      <router-link
        v-if="$route.meta.backUrl"
        class="button is-primary"
        style="margin-left: 1em"
        :to="'/calculation/' + $route.params.id + '/' + $route.meta.backUrl"
      >
        {{ $t('Tillbaka till anställda') }}
      </router-link>
      <template v-else>
        <router-link
          v-if="tabNavigation.prev !== null"
          class="button is-primary"
          :to="'/calculation/' + $route.params.id + '/' + tabNavigation.prev"
        >
          {{ $t('Tillbaka') }}
        </router-link>
        <span v-else />
        <router-link
          v-if="tabNavigation.next !== null"
          class="button is-primary"
          style="margin-left: 1em"
          :to="'/calculation/' + $route.params.id + '/' + tabNavigation.next"
        >
          {{ $t('Nästa') }}
        </router-link>
      </template>
    </div>
  </main-layout>
</template>

<script>
import { mapState } from 'vuex';
import MainLayout from '../layout/Main.vue';

export default {
  components: {
    MainLayout,
  },
  data() {
    return {
      title: '',
      id: '',
    };
  },
  computed: {
    ...mapState('account', ['region']),
    ...mapState('api', ['data']),
    tabNavigation() {
      const currentIndex = this.tabs.findIndex(
        (tab) => (this.$route.meta.activeTab || this.$route.meta.tab) == tab.path
      );
      return {
        next: currentIndex < this.tabs.length - 1 ? this.tabs[currentIndex + 1].path : null,
        prev: currentIndex > 0 ? this.tabs[currentIndex - 1].path : null,
      };
    },
    tabs() {
      switch (this.region) {
        case 'NO':
          return [
            {
              path: '',
              image: ['far', 'database'],
              title: 'Grunddata',
            },
            {
              path: 'employees',
              image: ['far', 'users'],
              title: 'Anställda',
            },
            {
              path: 'costs',
              image: ['far', 'dollar-sign'],
              title: 'Kostnader',
            },
            // {path: 'amortization', image: ['far', 'file-invoice-dollar'], title: 'Avskrivningar'},
            // {path: 'departments', image: ['far', 'share-alt-square'], title: 'Fördelning'},
            {
              path: 'summary',
              image: ['far', 'file-alt'],
              title: 'Sammanställning',
            },
            {
              path: 'budget',
              image: ['far', 'hand-holding-usd'],
              title: 'Budget',
            },
          ];
        default:
          return [
            {
              path: '',
              image: ['far', 'database'],
              title: 'Grunddata',
            },
            {
              path: 'employees',
              image: ['far', 'users'],
              title: 'Anställda',
            },
            {
              path: 'costs',
              image: ['far', 'dollar-sign'],
              title: 'Kostnader',
            },
            {
              path: 'amortization',
              image: ['far', 'file-invoice-dollar'],
              title: 'Avskrivningar',
            },
            {
              path: 'departments',
              image: ['far', 'share-alt-square'],
              title: 'Fördelning',
            },
            {
              path: 'summary',
              image: ['far', 'file-alt'],
              title: 'Sammanställning',
            },
            {
              path: 'budget',
              image: ['far', 'hand-holding-usd'],
              title: 'Budget',
            },
          ];
      }
    },
  },
  watch: {
    'data.title': function (title) {
      this.title = title || this.title;
    },
    'data._id': function (id) {
      this.id = id || this.id;
    },
  },
};
</script>
<style lang="scss" scoped>
// $primary: #98AEAC;

// .calc-id {
//     color: $primary;
// }

.field.space-between {
  display: flex;
  justify-content: space-between;
}

.field.has-text-centered .button {
  padding: 1.8em 5em;
  border: none;
  border-radius: 2px !important;
}
</style>
