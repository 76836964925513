import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Formatter from './formatter';
import { store } from '@/store';

Vue.use(VueI18n);

const regionToLocale = {
  US: 'en-US',
  SE: 'sv-SE',
  NO: 'nb-NO',
};

const locale = regionToLocale[store.getters['account/region']] || 'nb-NO';
const formatter = new Formatter({ locale });

export default new VueI18n({
  locale,
  formatter,
  messages: {
    'en-US': require('./en_US.json'),
    'sv-SE': require('./sv_SE.json'),
    'nb-NO': require('./nb_NO.json'),
  },
});
