import _ from 'lodash';
import moment from 'moment';
import { getData, postData, importUsersAndCompanies } from '../services';

const postDebounced = _.debounce(
  ({ commit }, { url, data, redirect, timestamp }) => {
    commit('post', {
      url,
      data,
      redirect,
      timestamp,
    });
  },
  500,
  { leading: false, trailing: true }
);

let loadStart = 0;
let loadingTimeout;
const setLoading = function (state, loading) {
  if (loading === true) {
    state.loading = true;
    loadStart = new Date().getTime() + 250;
  } else {
    clearTimeout(loadingTimeout);
    loadingTimeout = setTimeout(() => {
      state.loading = false;
    }, Math.max(0, loadStart - new Date().getTime()));
  }
};

export const api = {
  namespaced: true,
  state: {
    data: null,
    // partialData: null,
    path: null,
    formPath: null,
    form: null,
    oldForm: null,
    lastChange: null,
    changeTimestamps: {},
    formID: null,
    loading: false,
  },
  actions: {
    get({ commit }, url) {
      commit('get', url);
    },
    post({ commit, state }, { url, data, redirect, debounce = false }) {
      const timestamp = state.lastChange;
      if (debounce) {
        postDebounced(
          { commit },
          {
            url,
            data,
            redirect,
            timestamp,
          }
        );
      } else {
        commit('post', {
          url,
          data,
          redirect,
          timestamp,
        });
      }
    },
    path({ commit }, url) {
      commit('path', url);
    },
    formPath({ commit }, url) {
      commit('formPath', url);
    },
    form({ commit }, { key, val }) {
      commit('form', { key, val });
    },
    newForm({ commit }, data) {
      commit('newForm', data);
    },
    importUsersAndCompanies({ dispatch }, { file, sendEmail }) {
      importUsersAndCompanies(file, sendEmail).then(
        (response) => {
          console.log(response)
          dispatch('alert/success', 'Import started successfully', {
            root: true,
          });
        }
      ).catch((error) => {
        dispatch('alert/error', error, { root: true });
      })
    }
  },
  mutations: {
    path(state, url = null) {
      state.tab = url;
    },
    get(state, url) {
      const tempData = state.data;
      if (state.data == null || state.data.redirect || state.path !== url) {
        state.data = null;
        getData(url).then((response) => {
          state.data = response;
          state.path = url;
        });
      } else {
        state.data = null;
        setTimeout(() => {
          state.data = tempData;
        }, 1);
      }
    },
    post(state, { url, data, redirect = true, timestamp }) {
      url = url.replace('{id}', state.data._id);
      setLoading(state, true);
      state.oldData = { ...state.data };
      // if(!state.path.match(/^\/view\/calculation/i)) {
      //     state.data = null;
      // }
      postData(url, data)
        .then((response) => {
          if (!redirect && response.redirect) delete response.redirect;
          // console.log(response)
          if (response != '' && Object.keys(response).length > 0) {
            state.data = response;
            setLoading(state, false);
          } else {
            getData(state.path)
              .then((response) => {
                // Temporary while debugging
                // console.log(response)
                if (response != '') {
                  response.timestamp = timestamp;
                  state.data = response;
                  // state.data = response;
                  // state.path = url
                }
                setLoading(state, false);
              })
              .catch((e) => {
                console.error(e);
                state.loading = e;
              });
          }
        })
        .catch((e) => {
          console.error(e);
          state.loading = e;
        });
    },
    formPath(state, url) {
      state.formPath = url;
    },
    form(state, { key, val }) {
      if (state.form === null) {
        state.form = {};
      }
      state.lastChange = moment().valueOf();
      state.changeTimestamps[key] = state.lastChange;
      state.form[key] = val;
      state.form = { ...state.form };
    },
    newForm(state, data) {
      const setFormField = ({ key, val }) => {
        if (state.form === null) {
          state.form = {};
        }
        state.form[key] = val;
      };

      const recursive = (d) => {
        for (const key in d) {
          if (!Object.prototype.hasOwnProperty.call(d, key)) continue;
          const field = d[key];
          if (typeof field === 'object' && field !== null) {
            const updateField = (state.changeTimestamps[field.key] ?? 0) < (data.timestamp ?? 1);
            if (field.key && field.value != null && updateField) {
              if (
                !state.oldForm ||
                state.oldForm[field.key] != field.value ||
                state.oldForm[field.key] == null
              ) {
                setFormField({
                  key: field.key,
                  val: field.value,
                });
              }
            } else {
              recursive(field);
            }
          }
        }
      };
      if (data) {
        // Clear active field if formID changed
        // console.log({...data}, {...state})
        const idPath = data._id + state.formPath;
        if (idPath !== state.formID) {
          state.oldForm = null;
          state.form = {};
          state.changeTimestamps = {};
          state.formID = idPath;
        }

        // Add hidden fields
        if (data.form && data.form.fields) {
          for (let i = 0; i < data.form.fields.length; i++) {
            setFormField({
              key: data.form.fields[i].key,
              val: data.form.fields[i].value,
            });
          }
        }
        if (data.sections) recursive(data.sections);
        state.oldForm = { ...state.form };
      }
    },
  },
};
