<template>
  <fragment v-if="data.options">
    <label v-for="option in mergeOptions(data.options)" :key="option.key">
      <input
        :id="option.key"
        v-model="value"
        :value="option.key"
        :type="data.type.replace('input-', '')"
        :name="data.key"
        :class="['', data.className ? data.className : '']"
        :placeholder="option.placeholder"
        :required="option.required"
        :disabled="option.disabled"
        @focus="active = true"
        @blur="onBlur($event)"
      />
      {{ $t(option.value) }}
    </label>
  </fragment>
  <fragment v-else>
    <label v-if="data.text" :for="data.key" class="label">
      {{ $t(data.text) }}
    </label>
    <!-- <money
            v-if="data.attributes && types.includes(data.attributes.type)"
            :id="data.key"
            :key="data.key"
            v-model="value"
            v-bind="money"
            :name="data.key"
            :class="['input', (data.className ? data.className : '')]"
            :style="width()"
            :placeholder="data.placeholder"
            :required="data.required"
            :disabled="data.disabled"
            @blur.native="onBlur($event)"
            @focus.native="onFocus($event)" /> -->
    <currency-input
      v-if="types.includes(data.type.replace('input-', ''))"
      :id="data.key"
      :key="data.key"
      v-model="value"
      :name="data.key"
      :class="['input', data.className ? data.className : '']"
      :style="width()"
      :placeholder="data.placeholder"
      :required="data.required"
      :disabled="data.disabled"
      locale="fr"
      :currency="null"
      :distraction-free="false"
      :value-as-integer="false"
      :auto-decimal-mode="false"
      :precision="{ min: 0, max: 4 }"
      :value-range="range"
      @blur.native="onBlur($event)"
      @focus.native="
        onFocus($event);
        active = true;
      "
    />
    <input
      v-else
      :id="data.key"
      v-model="value"
      :type="data.type.replace('input-', '')"
      :name="data.key"
      :class="['input', data.className ? data.className : '']"
      :style="width()"
      :placeholder="data.placeholder"
      :required="data.required"
      :disabled="data.disabled"
      @blur="onBlur($event)"
      @focus="
        $event.target.setSelectionRange(0, value.length);
        active = true;
      "
    />
  </fragment>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { CurrencyInput, parse } from 'vue-currency-input';
// import { Money } from 'v-money'
import { mergeOptions } from '../helpers';

export default {
  components: {
    // Money,
    CurrencyInput,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      // money: {
      //     decimal: ",",
      //     thousands: " ",
      //     suffix: "",
      //     precision: 0,
      //     masked: false,
      // },
      types: ['number', 'money', 'percent', 'time'],
      nValue: null,
      active: false,
    };
  },
  computed: {
    ...mapState({
      apiForm: (state) => state.api.form,
      oldApiForm: (state) => state.api.oldForm,
    }),
    range() {
      const range = {
        min: parseFloat(this.data?.attributes?.min || 0),
        max: parseFloat(this.data?.attributes?.max || 0),
      };
      return range.max !== 0 || range.min !== 0 ? range : null;
    },
    value: {
      set(val) {
        val = val != null ? val : '';

        if (this.apiForm[this.data.key] != val) {
          this.nValue = val;
          this.setFormField({ key: this.data.key, val });
          this.onChange({ key: this.data.key, value: val });
        }
      },
      get() {
        const val = this.active && this.nValue !== null ? this.nValue : this.apiForm[this.data.key];
        // console.log(this.data.key, val, this.apiForm[this.data.key])
        if (this.types.includes(this.data.type.replace('input-', ''))) {
          return val != null ? parse(val.toString()) : val || 0;
        }
        return val;
      },
    },
  },
  // mounted () {
  //     if(this.data.attributes) {
  //         this.money.suffix = (this.data.attributes.type == "money") ? " kr" : ""
  //         this.money.precision = parseInt(this.data.attributes.precision) || 0
  //     } else {
  //         this.money.thousands = ""
  //     }
  // },
  methods: {
    mergeOptions,
    ...mapActions('api', {
      setFormField: 'form',
    }),
    width() {
      const inputType = this.data.type.replace('input-', '');
      if (inputType == 'hidden') return undefined;
      const defaultWidth = inputType == 'money' ? 100 : 80;
      return this.data.style ? this.data.style : `width: ${defaultWidth}px`;
    },
    onChange(data = { key: this.data.key, value: this.value }) {
      this.$emit('change', data);
    },
    onBlur() {
      this.active = false;
      this.$emit('blur', { key: this.data.key, value: this.value });
    },
    onFocus(e) {
      this.$nextTick(() => {
        setTimeout(() => {
          if (e.target) e.target.setSelectionRange(0, e.target.value.length);
        }, 0);
      });
    },
    updateValue(value) {
      this.nValue = value;
    },
  },
};
</script>

<style lang="scss" scope>
label.rows {
  display: block;
}

input.text-align-left {
  text-align: left !important;
}

input.radio-padding {
  margin-left: 1rem;
  margin-right: 0 !important;
}
label:first-child input.radio-padding {
  margin-left: 0;
}
</style>
