<template>
  <fragment>
    <LineChart
      v-if="showChart && chartdata"
      ref="chart"
      class="column is-full"
      :chart-data="chartdata"
      :options="chartoptions"
      :height="200"
    />
  </fragment>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import LineChart from './LineChart';

export default {
  components: {
    LineChart,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
    showChart: {
      type: Boolean,
      default: true,
    },
  },
  computed: {
    ...mapState({}),
    chartdata() {
      // Create dataset meta
      const meta = [];
      for (const row of this.data.rows) {
        meta.push({
          label: this.$t(row?.meta?.label || row.title || row.key),
          color: row?.meta?.color || '',
          rows: [],
        });
        for (const columns of row.columns) {
          const field = columns?.fields[0];
          if (field.key) {
            const label = field.key.match(/[A-Za-z]+$/)[0];
            meta[meta.length - 1]?.rows.push({
              x: this.$t(label),
              // y: field.value || Math.random() * 1000
              y: field.value || 0,
            });
          }
        }
      }

      if (meta.length == 0) {
        return null;
      }

      // Create datasets
      const datasets = [];
      for (const m of meta) {
        datasets.push({
          label: m.label,
          borderColor: m.color,
          backgroundColor: m.color,
          hoverBackgroundColor: 'white',
          pointHitRadius: 10,
          data: m.rows,
          fill: false,
        });
      }

      return {
        labels: meta[0].rows.map((r) => r.x),
        datasets,
      };
    },
    chartoptions() {
      // const vue = this
      return {
        responsive: true,
        maintainAspectRatio: false,
        showAllTooltips: true,

        tooltips: {
          // yAlign: 'bottom',
          // xAlign: 'left',
          caretSize: 0,
          // cornerRadius: 0,
          titleFontSize: 16,
          bodyFontSize: 16,
          position: 'average',
          mode: 'index',
          intersect: false,
          bodyFontStyle: 'bold',
          callbacks: {
            label(tooltipItem, data) {
              const dataset = data.datasets[tooltipItem.datasetIndex];
              const current = dataset.data[tooltipItem.index].y;
              // const previous = tooltipItem.index > 0 ? dataset.data[tooltipItem.index - 1].y : dataset.firstData
              // const value = 1000
              // // const value = vue.$decimal(tooltipItem.value).div(previous).sub(1).mul(100)

              // if (tooltipItem.index === 0) {
              //     return `${tooltipItem.value} kr`
              // } else if (!previous || current > previous) {
              //     return '+' + value + '%'
              // } else {
              //     return value + '%'
              // }

              return ` ${Math.round(current * 100) / 100} kr`;
            },
            // labelTextColor (tooltipItem, chart) {
            //     const dataset = chart.data.datasets[tooltipItem.datasetIndex]
            //     const current = dataset.data[tooltipItem.index].y
            //     const previous = tooltipItem.index > 0 ? dataset.data[tooltipItem.index - 1].y : dataset.firstData
            //     if (tooltipItem.index === 0) {
            //         return 'rgb(90, 90, 90)'
            //     } else if (!previous || current > previous) {
            //         return 'rgb(90, 90, 90)'
            //     } else {
            //         return 'red'
            //     }
            // },
            title(data) {
              let { label } = data[0];
              label = label.charAt(0).toUpperCase() + label.slice(1);
              return label;
            },
          },
        },
        legend: {
          display: true,
        },
        scales: {
          yAxes: [
            {
              ticks: {
                display: true,
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontStyle: 'bold',
                // fontSize: 16
              },
              gridLines: {
                color: '#EAEAEA',
                z: 0,
              },
            },
          ],
        },
      };
    },
  },
  watch: {},
  methods: {
    ...mapActions({}),
  },
};
</script>

<style lang="scss" scope></style>
