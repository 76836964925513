<template>
  <fragment>
    <h2 v-if="data.size == 'h2'" :class="[data.className]">
      {{ $t(data.text) }}
    </h2>
    <h3 v-else-if="data.size == 'h3'" :class="[data.className]">
      {{ $t(data.text) }}
    </h3>
    <h4 v-else-if="data.size == 'h4'" :class="[data.className]">
      {{ $t(data.text) }}
    </h4>
    <h1 v-else :class="[data.className]">
      {{ $t(data.text) }}
    </h1>
  </fragment>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
};
</script>

<style lang="scss" scope></style>
