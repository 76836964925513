<template>
  <figure :class="['image', data.className ? data.className : '']" :data-tooltip="$t(data.tooltip)">
    <img :src="url" />
  </figure>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  computed: {
    url() {
      return process.env.VUE_APP_FILEURL + this.data.value;
    },
  },
};
</script>

<style lang="scss" scope></style>
