<template>
  <fragment>
    <div v-if="modal && !data.action" class="button-container">
      <modal v-show="modal && showModal" :title="modal.title" @close="closeModal" @save="sendForm">
        <Form v-if="modal.form" ref="form" :data="modal.form" @input="form = $event" />
      </modal>
      <button
        :class="['button', data.className]"
        :tabindex="tabindex"
        type="button"
        @click="openModal"
      >
        <span v-if="data.icon" class="icon">
          <font-awesome-icon :icon="data.icon" />
        </span>
        <span v-if="data.text">{{ $t(data.text) }}</span>
      </button>
    </div>
    <div v-if="!modal || data.action" class="button-container">
      <button
        :class="['button', data.className]"
        :data-tooltip="data.tooltip"
        :tabindex="tabindex || '-1'"
        type="button"
        @click="getAction"
      >
        <span v-if="data.icon" class="icon">
          <font-awesome-icon :icon="data.icon" />
        </span>
        <span v-if="data.text">{{ $t(data.text) }}</span>
      </button>
    </div>
  </fragment>
</template>

<script>
import { mapActions } from 'vuex';
import _ from 'lodash';
import Modal from './Modal.vue';
import Form from './Form.vue';

export default {
  components: {
    Modal,
    Form,
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      form: {},
      showModal: false,
      sending: false,
      invalidForm: false,
    };
  },
  computed: {
    modal() {
      return !_.isEmpty(this.data.modal.form) ? this.data.modal : null;
    },
    tabindex() {
      return this.data?.attributes?.tabindex || '';
    },
    type() {
      const split = this.data?.type?.split('-') ?? [];
      return split.length > 1 ? split[1] : '';
    },
  },
  methods: {
    ...mapActions('api', {
      postForm: 'post',
    }),
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    sendForm() {
      this.invalidForm = !this.validForm();
      if (!this.invalidForm) {
        this.sending = true;
        if (this.$refs?.form) {
          this.$refs.form.send();
        } else {
          console.error('Form ref not found');
        }
        this.showModal = false;
      }
    },
    validForm() {
      let errors = 0;
      for (let i = 0; i < this.data.modal.form.fields.length; i++) {
        const field = this.data.modal.form.fields[i];
        const value = this.form[field.key];

        if (!field.required) continue;

        field.error = !value ? this.$t('Kan ej vara tomt') : null;
        if (!value) errors++;
        field.value = this.form[field.key];

        this.$set(this.data.modal.form.fields, i, field); // Make sure Vue triggers an update
      }

      if (errors > 0) {
        return false;
      }
      return true;
    },
    getAction() {
      switch (this.type) {
        case 'confirm':
          var dialogText = this.$t(
            this.data.attributes?.message || 'Är du säker att du vill ta bort?',
            this.data.attributes
          );
          this.$dialog
            .confirm(dialogText, {
              okText: this.$t('Fortsätt'),
              cancelText: this.$t('Avbryt'),
            })
            .then(() => {
              this.postForm({
                url: this.data.link || this.data.action,
                data: {},
              });
            })
            .catch(() => {
              console.warn('User cancelled action');
            });
          break;
        default:
          this.postForm({
            url: this.data.link || this.data.action,
            data: {},
          });
      }
    },
  },
};
</script>

<style lang="scss" scope>
.button {
  font-size: 0.9em;
  padding: 1.3em;
  span {
    padding-right: 0 !important;
  }
}
</style>
