<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style lang="scss">
@import '~bulma/sass/utilities/_all';
@import '~balloon-css/src/balloon';

/* fonts */

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'EuclidCircular';
  src: local('EuclidCircular'),
    url(assets/fonts/EuclidCircularA-Regular.ttf) format('truetype');
}
@font-face {
  font-family: 'DroidSansMonoSlashed';
  src: local('DroidSansMonoSlashed'),
    url(assets/fonts/DroidSansMonoSlashed.ttf) format('truetype');
}

$eCircular: 'EuclidCircular', sans-serif;
$droidslashed: 'DroidSansMonoSlashed', sans-serif;

body {
  font-family: $eCircular !important;
  color: $text;
}

.button {
  font-family: $eCircular;
}

.calc-subtitle,
h1,
h2,
h3,
h4,
h5,
h6,
th {
  font-weight: bold !important;
}

.table thead td,
.table thead th {
  border: none;
}

.calc-id {
  font-family: $droidslashed;
  font-weight: 300 !important;
}

/* end of fonts */
/* colors */

$primary: #4e5863;
$secondary: #98aeac;
$text: #2a2c31;
$red: #a34440;
$green: #1faf51;
$active: #c3d9d8;

$primary-invert: findColorInvert($primary);

$colors: (
  'white': (
    $white,
    $black,
  ),
  'black': (
    $black,
    $white,
  ),
  'light': (
    $light,
    $light-invert,
  ),
  'dark': (
    $dark,
    $dark-invert,
  ),
  'primary': (
    $primary,
    $primary-invert,
  ),
  'info': (
    $info,
    $info-invert,
  ),
);

$link: $text;
$link-invert: $primary-invert;
$link-focus-border: $primary;

$navbar-dropdown-background-color: #9d9d9d;
$navbar-dropdown-border-top: none;
$navbar-dropdown-item-hover-background-color: #777;
$navbar-dropdown-item-hover-color: #fff;

/* end of colors */

@import '~bulma';
@import '~buefy/src/scss/buefy';
@import '~@creativebulma/bulma-tooltip/src/sass';

$sizes: (0, 5, 10, 15, 20, 25, 30, 50, 70);
$positions: ('top', 'left', 'bottom', 'right');

@each $size in $sizes {
  .has-margin-#{$size} {
    margin: $size + px;
  }
  .has-padding-#{$size} {
    padding: $size + px;
  }
  @each $position in $positions {
    .has-margin-#{$position}-#{$size} {
      margin-#{$position}: $size + px;
    }
    .has-padding-#{$position}-#{$size} {
      padding-#{$position}: $size + px;
    }
  }
}

/* general styling */

.hidden {
  display: none;
  opacity: 0;
}

.secondary-color {
  color: $secondary;
}

.tabs {
  ul {
    border-bottom: 1px solid grey;
    li {
      padding-bottom: 1px;
      a {
        outline: 0;
        border-bottom: none;
        font-weight: bold;
        padding: 0.8em 1.5em;
        border-bottom: none;
        &.is-active {
          background: $active;
        }
      }
    }
  }
}
table span {
  overflow-wrap: break-word;
}

td button {
  float: right;
}

td div.button-container + .button-container {
  margin-left: 0.25rem;
}

td span.has-text-weight-bold {
  padding-right: 0.4em;
}

button,
.notification {
  border-radius: 2px !important;
}

.is-danger {
  background: $red !important;
}

.has-text-success {
  color: $green !important;
}

.has-text-danger {
  color: $red !important;
}

.input {
  &:focus {
    border: 1px solid #c3d9d8;
  }
}

/* logout container */
.dg-backdrop {
  background-color: rgba(10, 10, 10, 0.55);
  backdrop-filter: blur(4px);
}

.dg-main-content {
  border-radius: unset;
  padding: 0;
  max-width: 600px;
  .dg-content-body {
    padding: 4.5em;
    text-align: center;
    border: none;
    font-weight: bold;
  }
  .dg-content-footer {
    padding: 0;
    button {
      border-radius: unset !important;
      margin: 0 !important;
      padding: 1.5em;
      font-weight: normal;
      &.dg-btn--cancel {
        background: $dark;
        width: 65%;
        border: none;
      }
      &.dg-btn--ok {
        background: $primary;
        color: white;
        border: none;
        width: 35%;
      }
    }
  }
}

.is-light-grey {
  color: #b4bab8;
}

.has-text-weight-bold input.is-string {
  font-weight: 700 !important;
}
.has-text-centered input.is-string {
  text-align: center !important;
}
/* end of logout container */

/* all checkboxes */

input[type='checkbox'] {
  width: 25px;
  height: 25px;
  position: relative;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border: none !important;
  &:hover {
    &::after {
      background: rgba(211, 211, 211, 0.5);
    }
  }
  &::after,
  &:checked::before {
    content: '';
    position: absolute;
    width: 26px;
    height: 26px;
    top: 0.56em;
    left: 0;
    border: 2px solid black;
    border-radius: 3px;
  }
  &:checked {
    position: relative;
    &::before {
      background-color: white;
    }
    &::after {
      content: '';
      left: 0.5em;
      top: 14px;
      width: 1em;
      height: 1em;
      border: 5px solid black;
      border-radius: 344343px;
      background: black;
    }
  }

  &.is-small {
    width: 20px;
    height: 20px;
    margin-left: 0.5em;
    // margin-top: -0.5em;

    &::after,
    &:checked::before {
      width: 20px;
      height: 20px;
      top: 0em;
      left: 0;
      border: 2px solid black;
      border-radius: 3px;
    }
    &:checked {
      &::after {
        left: 0.3em;
        top: 4px;
        width: 1em;
        height: 1em;
        border: 3px solid black;
        border-radius: 344343px;
        background: black;
      }
    }
  }
}
/*end of all checkboxes */

/* end of general styling */

.icon svg {
  width: 1em;
}
</style>
